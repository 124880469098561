import React from "react";
import { TranslateFunction } from "./I18nTypes";
import { IProps as ITextFieldProps } from "./TextField";
import { IProps as IButtonSelectProps } from "./ButtonSelect";
import { IProps as INumberFieldProps } from "./NumberField";
import { DropdownProps } from "./Dropdown";
import { IDetailBaseButton, DetailBase } from "./DetailBase";

type AllowedChildType =
    | ITextFieldProps
    | IButtonSelectProps
    | INumberFieldProps
    | DropdownProps;

export interface IDetailProps {
    title: string;
    children:
        | Array<React.ReactElement<AllowedChildType>>
        | React.ReactElement<AllowedChildType>;
    edited?: boolean;
    errors?: string[];
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    onSave?: () => void;
    onCancel?: () => void;
    onDelete?: () => void;
    collapsible?: boolean;
    translate: TranslateFunction;
    loading?: boolean;
    isSaving?: boolean;
}

export const Detail: React.FC<IDetailProps> = ({
    children,
    title,
    edited,
    open,
    onOpen,
    onClose,
    onCancel,
    onSave,
    collapsible = true,
    translate: t,
    loading,
    isSaving,
    ...props
}) => {
    const buttons: IDetailBaseButton[] = [];
    if (edited) {
        buttons.push(
            {
                label: t("app.save"),
                buttonType: "primary",
                onClick: onSave!,
                loading: isSaving,
            },
            {
                label: t("app.cancel"),
                buttonType: "secondary",
                onClick: onCancel!,
                disabled: isSaving,
            }
        );
    }

    return (
        <DetailBase
            buttons={buttons}
            {...{
                children,
                title,
                edited,
                open,
                onOpen,
                onClose,
                collapsible,
                translate: t,
                loading,
            }}
            {...props}
        />
    );
};
