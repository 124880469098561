import React from "react";
import FlexView from "react-flexview";

interface IColumnProps {
    children: React.ReactChild;
}

interface ITwoColumnLayoutProps {
    children: [React.ReactElement, React.ReactElement];
}

interface ReactFCTwoColumnLayout extends React.FC<ITwoColumnLayoutProps> {
    Left: React.FC<IColumnProps>;
    Right: React.FC<IColumnProps>;
}

const Column: React.FC<IColumnProps> = ({ children }) => (
    <FlexView
        grow={1}
        shrink={1}
        basis="1em"
        marginLeft="0.5em"
        marginRight="0.5em"
        marginTop="0.5em"
        marginBottom="0.5em"
        style={{
            minWidth: "min-content",
            alignItems: "flex-start",
        }}
    >
        {children}
    </FlexView>
);

export const TwoColumnLayout: ReactFCTwoColumnLayout = ({ children }) => {
    const childArray = React.Children.toArray(children) as React.ReactElement[];

    if (childArray.length === 2) {
        if (childArray[0]?.type !== TwoColumnLayout.Left) {
            throw new Error(
                "Unexpected subcomponent at index 0: Requires Left"
            );
        }

        if (childArray[1]?.type !== TwoColumnLayout.Right) {
            throw new Error(
                "Unexpected subcomponent at index 1: Requires Right"
            );
        }
    } else {
        throw new Error(
            "All mandatory subcomponents must be present, each exactly once, with no additional subcomponents"
        );
    }

    return (
        <FlexView
            wrap
            marginLeft="-0.5em"
            marginRight="-0.5em"
            marginTop="-0.5em"
            marginBottom="-0.5em"
            style={{ minWidth: "min-content" }}
        >
            <Column>{childArray[0].props.children}</Column>
            <Column>{childArray[1].props.children}</Column>
        </FlexView>
    );
};

TwoColumnLayout.Left = () => <></>;
TwoColumnLayout.Right = () => <></>;
