import React, {
    ChangeEvent,
    KeyboardEvent,
    useState,
    useEffect,
    useRef,
} from "react";
import {
    Form,
    Input,
    InputOnChangeData,
    SemanticICONS,
} from "semantic-ui-react";
import uniqueId from "lodash.uniqueid";

export interface IProps {
    value: string;
    label: string;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    maxChars?: number;
    type?: "text" | "password";
    icon?: SemanticICONS;
    onChange: (value: string) => void;
    onValidate?: (value: string) => void;
    onFocus?: () => void;
}

export const TextField: React.FC<IProps> = ({
    value,
    label,
    error,
    required,
    disabled,
    maxChars,
    type,
    icon,
    onChange,
    onValidate,
    onFocus,
    children,
}) => {
    const handleValue = (value: string) => {
        if (maxChars && value.length > maxChars) {
            return value.substr(0, maxChars);
        } else {
            return value;
        }
    };

    const [focused, setFocused] = useState<boolean>(false);
    const [id] = useState(uniqueId("input_"));
    const [displayValue, setDisplayValue] = useState<string>(
        handleValue(value)
    );
    const timerRef = useRef<NodeJS.Timeout | number | null>(null);
    const [displayedError, setDisplayedError] = useState(error);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        }

        const newError = focused ? "" : error;

        if (newError) {
            timerRef.current = setTimeout(() => {
                setDisplayedError(newError);
            }, 250);
        } else {
            setDisplayedError(newError);
        }
    }, [error, focused]);

    const onValueChange = (
        _event: ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
    ) => {
        const newValue = handleValue(data.value);
        setDisplayValue(newValue);
        onChange(newValue);
        onValidate?.(newValue);
    };

    const onKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            onValidate?.(value);
        }
    };

    useEffect(() => {
        setDisplayValue(handleValue(value));
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form.Field
            id={id}
            value={displayValue}
            label={{ children: label, htmlFor: id }}
            error={displayedError ? { content: displayedError } : undefined}
            required={required}
            disabled={disabled}
            type={type || "text"}
            icon={icon}
            iconPosition={icon ? "left" : undefined}
            onChange={onValueChange}
            onFocus={() => {
                setFocused(true);
                onFocus?.();
            }}
            onBlur={() => {
                setFocused(false);
                onValidate?.(value);
            }}
            onKeyPress={onKeyPress}
            control={Input}
        >
            {children}
        </Form.Field>
    );
};
