import React from "react";
import FlexView from "react-flexview/lib";
import { Menu, Image } from "semantic-ui-react";
import { IVerticalMenuItem, VerticalMenuItem } from "./VerticalMenuItem";
import { useHistory } from "react-router-dom";

interface ILogo {
    src: string;
    link?: string;
    linkTarget?: "blank" | "self";
}

interface ILogoProps extends ILogo {
    id: string;
}

interface IVerticalMenuProps {
    topItems: IVerticalMenuItem[];
    bottomItems: IVerticalMenuItem[];
    logo?: ILogo;
    subLogo?: ILogo;
}
export const VerticalMenu: React.FC<IVerticalMenuProps> = ({
    topItems,
    bottomItems,
    logo,
    subLogo,
}) => {
    return (
        <div className={"menu-wrapper"}>
            <Menu as={FlexView} column fixed="left" vertical>
                {logo && <Logo {...{ ...logo, id: "logo" }} />}
                {subLogo && <Logo {...{ ...subLogo, id: "subLogo" }} />}
                {topItems.map((props, index) => (
                    <VerticalMenuItem key={`top${index}`} {...props} />
                ))}
                <FlexView grow />
                {bottomItems.map((props, index) => (
                    <VerticalMenuItem key={`bottom_${index}`} {...props} />
                ))}
            </Menu>
        </div>
    );
};

const Logo: React.FC<ILogoProps> = ({ src, link, linkTarget, id }) => {
    const history = useHistory();

    const onLogoClick = () => {
        if (link) {
            if (linkTarget === undefined) {
                history.push(link);
            } else {
                window.open(link, `_${linkTarget}`);
            }
        }
    };

    return (
        <Menu.Item
            className={`logo${link ? " link" : ""}`}
            key={id}
            onClick={onLogoClick}
            data-testid={id}
        >
            <Image src={src} />
        </Menu.Item>
    );
};
