import React, { KeyboardEvent, useState, useEffect, useRef } from "react";
import { Form } from "semantic-ui-react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import uniqueId from "lodash.uniqueid";
import format from "format-number";

export interface IProps {
    value?: number;
    label?: string;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    minValue?: number;
    maxValue?: number;
    decimalCount?: number;
    onChange: (value: number | undefined) => void;
    onValidate?: (value: number | undefined) => void;
    onFocus?: () => void;
    getRef?: (ref: HTMLDivElement | undefined) => void;
}

const thousandSeparator = "’";

export const formatValue = (val: number, decimalCount?: number) => {
    const myFormat = format({
        integerSeparator: thousandSeparator,
        padRight: decimalCount === undefined ? -1 : decimalCount,
        truncate: decimalCount === undefined ? 0 : decimalCount,
    });

    return myFormat(val);
};

export const NumberField: React.FC<IProps> = ({
    value,
    label,
    error,
    required,
    disabled,
    minValue,
    maxValue,
    decimalCount,
    onChange,
    onValidate,
    onFocus,
    children,
    getRef,
}) => {
    const [id] = useState(uniqueId("input_"));
    const [displayValue, setDisplayValue] = useState<number | undefined>(value);
    const [focused, setFocused] = useState<boolean>(false);
    const [displayedError, setDisplayedError] = useState(error);
    const timerRef = useRef<NodeJS.Timeout | number | null>(null);

    const clampValue = (valueToBeClamped: number | undefined) => {
        if (valueToBeClamped !== undefined) {
            if (minValue !== undefined && valueToBeClamped < minValue) {
                return minValue;
            }

            if (maxValue !== undefined && valueToBeClamped > maxValue) {
                return maxValue;
            }
        }

        return valueToBeClamped;
    };

    const onValueChange = (values: NumberFormatValues) => {
        const newValue = values.floatValue;

        setDisplayValue(newValue);
        onChange(newValue);

        onValidate?.(newValue);
    };

    const onBlur = () => {
        const clampedValue = clampValue(displayValue);

        if (clampedValue !== displayValue) {
            setDisplayValue(clampedValue);
        }

        setFocused(false);
        onChange(clampedValue);
        onValidate?.(clampedValue);
    };

    const onKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            const clampedValue = clampValue(displayValue);

            if (clampedValue !== displayValue) {
                setDisplayValue(clampedValue);
            }

            onChange(clampedValue);
            onValidate?.(clampedValue);
        }
    };

    const onFocused = () => {
        setFocused(true);
        onFocus?.();
    };

    useEffect(() => {
        setDisplayValue(value);
    }, [value]);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        }

        const newError = focused ? "" : error;

        if (newError) {
            timerRef.current = setTimeout(() => {
                setDisplayedError(newError);
            }, 250);
        } else {
            setDisplayedError(newError);
        }
    }, [error, focused]);

    return (
        <Form.Field
            data-testid={"number-field"}
            id={id}
            value={displayValue}
            label={{ children: label, htmlFor: id }}
            error={displayedError ? { content: displayedError } : undefined}
            required={required}
            disabled={disabled}
            thousandSeparator={thousandSeparator}
            decimalSeparator={"."}
            allowedDecimalSeparators={[",", "."]}
            fixedDecimalScale={true}
            decimalScale={decimalCount ? decimalCount : 0}
            allowNegative={minValue === undefined || minValue < 0}
            onValueChange={onValueChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onFocus={onFocused} //warning! is called asynchronously!
            control={NumberFormat}
            getInputRef={(el: HTMLInputElement) => {
                getRef?.(el);
            }}
        >
            {children}
        </Form.Field>
    );
};
