import React, { useLayoutEffect, useState } from "react";
import { getWindowDimensions } from "./WindowDimensions";

export const WindowWidth = {
    veryLarge: 2000,
    large: 1600,
    medium: 1200,
    small: 992,
    min: 768,
    mobile: 767,
};
interface IProps {
    children: React.ReactElement | React.ReactElement[];
    onWidthChange?: (width: number, height: number) => void;
}

export const Responsive: React.FC<IProps> = ({ children, onWidthChange }) => {
    useWindowSize(onWidthChange);
    return <>{children}</>;
};

const useWindowSize = (onResize?: (width: number, height: number) => void) => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            const [width, height] = getWindowDimensions();
            setSize([width, height]);
            onResize?.(width, height);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [onResize]);
    return size;
};
