import React from "react";
import { Container } from "semantic-ui-react";
import { extractComponent } from "./extractComponent";
import FlexView from "react-flexview/lib";

interface IProps {
    children: React.ReactNode;
}

interface ReactFCAppLayout extends ReactFCAppLayoutContent {
    Menu: React.FC<IProps>;
    Content: React.FC<IProps>;
}

interface ReactFCAppLayoutContent extends React.FC<IProps> {
    Header: React.FC<IProps>;
    Body: React.FC<IProps>;
    Footer: React.FC<IProps>;
}

export const AppLayout: ReactFCAppLayout = ({ children }) => {
    if (React.Children.count(children) === 2) {
        const childArray = React.Children.toArray(
            children
        ) as React.ReactElement[];
        if (childArray[0]?.type !== AppLayoutMenu) {
            throw new Error(
                "Unexpected subcomponent at index 0: Requires AppLayoutMenu"
            );
        }
        if (childArray[1]?.type !== AppLayoutContent) {
            throw new Error(
                "Unexpected subcomponent at index 1: Requires AppLayoutContent"
            );
        }
    } else {
        throw new Error(
            "All mandatory subcomponents must be present, each exactly once, with no additional subcomponents"
        );
    }

    return <div className={"appLayout"}>{children}</div>;
};

const AppLayoutContent: ReactFCAppLayoutContent = ({ children }) => {
    let headerComponent = null;
    let bodyComponent = null;
    let footerComponent = null;

    const childArray = React.Children.toArray(children) as React.ReactElement[];
    childArray.forEach((child) => {
        const extractedComponents = extractComponent(
            child,
            {
                header: AppLayoutHeader,
                body: AppLayoutBody,
                footer: AppLayoutFooter,
            },
            1
        );
        if (extractedComponents.header) {
            headerComponent = extractedComponents.header as React.ReactElement<IProps>;
        }
        if (extractedComponents.body) {
            bodyComponent = extractedComponents.body as React.ReactElement<IProps>;
        }
        if (extractedComponents.footer) {
            footerComponent = extractedComponents.footer as React.ReactElement<IProps>;
        }
    });

    return (
        <FlexView column className={"appLayout-content"} grow>
            {headerComponent}
            {bodyComponent}
            {footerComponent}
        </FlexView>
    );
};

const AppLayoutMenu: React.FC<IProps> = ({ children }) => {
    return <>{children}</>;
};

const AppLayoutBody: React.FC<IProps> = ({ children }) => {
    return (
        <FlexView
            grow
            column
            style={{ minHeight: "unset" }}
            className={"appLayout-body"}
        >
            <Container>{children}</Container>
        </FlexView>
    );
};

const AppLayoutFooter: React.FC<IProps> = ({ children }) => {
    return (
        <FlexView column>
            <>{children}</>
        </FlexView>
    );
};

const AppLayoutHeader: React.FC<IProps> = ({ children }) => {
    return <div className={"appLayout-header"}>{children}</div>;
};

AppLayout.Menu = AppLayoutMenu;
AppLayout.Content = AppLayoutContent;
AppLayout.Header = AppLayoutHeader;
AppLayout.Body = AppLayoutBody;
AppLayout.Footer = AppLayoutFooter;
AppLayoutContent.Header = AppLayout.Header;
AppLayoutContent.Body = AppLayout.Body;
AppLayoutContent.Footer = AppLayout.Footer;
