import React from "react";
import { IDetailProps } from "./Detail";

export interface ITableDetailProps extends IDetailProps {
    onSave: () => Promise<boolean>;
}

export const TableDetail: React.FC<ITableDetailProps> = () => {
    throw new Error("should not be rendered");
};
