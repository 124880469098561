import React from "react";
import { TranslateFunction } from "./I18nTypes";
import { DetailBase, IDetailBaseButton } from "./DetailBase";
import { Modal } from "semantic-ui-react";
import { IDetailProps } from "./Detail";

interface IProps extends IDetailProps {
    onCloseButton?: () => void;
    buttonsLabel?: {
        save?: string;
        cancel?: string;
        delete?: string;
        close?: string;
    };
    translate: TranslateFunction;
}

export const ModalDetail: React.FC<IProps> = (props) => {
    const buttons: IDetailBaseButton[] = [];
    const buttonsLabel = props.buttonsLabel;
    const t = props.translate;
    if (props.edited) {
        buttons.push(
            {
                label: buttonsLabel?.save ? buttonsLabel?.save : t("app.save"),
                buttonType: "primary",
                onClick: props.onSave!,
                disabled: props.loading,
                loading: props.isSaving,
            },
            {
                label: buttonsLabel?.cancel
                    ? buttonsLabel?.cancel
                    : t("app.cancel"),
                buttonType: "secondary",
                onClick: props.onCancel!,
                disabled: props.loading || props.isSaving,
            }
        );
    }
    if (props.onDelete) {
        buttons.push({
            label: buttonsLabel?.delete
                ? buttonsLabel?.delete
                : t("app.delete"),
            buttonType: "negative",
            onClick: props.onDelete,
            disabled: props.loading || props.isSaving,
        });
    }
    if (props.onCloseButton) {
        buttons.push({
            label: buttonsLabel?.close ? buttonsLabel?.close : t("app.close"),
            buttonType: "default",
            onClick: props.onCloseButton,
        });
    }
    return (
        <Modal open data-testid="modal">
            <DetailBase {...props} buttons={buttons} collapsible={false} />
        </Modal>
    );
};
