import React, { useState } from "react";
import { CollapsibleSegment } from "./CollapsibleSegment";
import FlexView from "react-flexview";
import { Form, Message } from "semantic-ui-react";
import { VerticalSpace } from "./VerticalSpace";
import { TranslateFunction } from "./I18nTypes";
import { IProps as ITextFieldProps, TextField } from "./TextField";
import { IProps as IButtonSelectProps, ButtonSelect } from "./ButtonSelect";
import { IProps as INumberFieldProps, NumberField } from "./NumberField";
import { DropdownProps, Dropdown } from "./Dropdown";
import { ResponsiveButton } from "./ResponsiveButton";
import { DateInput } from "./DateInput";
import { Responsive } from "./Responsive";
import { HorizontalSpace } from "./HorizontalSpace";
import { WindowWidth } from "./Responsive";

type AllowedChildType =
    | ITextFieldProps
    | IButtonSelectProps
    | INumberFieldProps
    | DropdownProps;

export interface IDetailBaseButton {
    label: string;
    buttonType: "primary" | "secondary" | "negative" | "default";
    onClick: () => void;
    loading?: boolean;
    disabled?: boolean;
}

export interface IDetailBaseProps {
    title: string;
    children:
        | Array<React.ReactElement<AllowedChildType>>
        | React.ReactElement<AllowedChildType>;
    edited?: boolean;
    errors?: string[];
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    collapsible?: boolean;
    buttons: IDetailBaseButton[];
    translate: TranslateFunction;
    loading?: boolean;
}

export const DetailBase: React.FC<IDetailBaseProps> = ({
    children,
    title,
    edited,
    open,
    onOpen,
    onClose,
    collapsible,
    buttons,
    translate: t,
    loading,
    ...props
}) => {
    const errors = props.errors?.length ? (
        <Message negative>
            <Message.Header>{t("app.error")}</Message.Header>
            {props.errors.map((error) => (
                <Message.Item key={error}>{t(error)}</Message.Item>
            ))}
        </Message>
    ) : (
        []
    );

    if (Array.isArray(children)) {
        React.Children.forEach(children, (child, index) => {
            if (
                child.type !== TextField &&
                child.type !== NumberField &&
                child.type !== DateInput &&
                child.type !== ButtonSelect &&
                child.type !== Dropdown
            ) {
                throw new Error(`Unexpected subcomponent at index ${index}`);
            }
        });
    } else {
        if (
            children.type !== TextField &&
            children.type !== NumberField &&
            children.type !== ButtonSelect &&
            children.type !== DateInput &&
            children.type !== Dropdown
        ) {
            throw new Error(`Unexpected subcomponent`);
        }
    }

    const [screen, setScreen] = useState<"mobile" | "desktop">("desktop");

    const footerDesktop = buttons.length > 0 && (
        <>
            <FlexView wrap marginTop="-0.5em" marginBottom="-0.5em">
                {buttons.map((button, index) => (
                    <FlexView
                        marginTop="0.5em"
                        marginBottom="0.5em"
                        key={`button${index}`}
                    >
                        <ResponsiveButton
                            fluid={false}
                            label={button.label}
                            {...{
                                [button.buttonType]: true,
                            }}
                            onAction={button.onClick}
                            disabled={button.disabled}
                            loading={button.loading}
                        />
                        {index < buttons.length - 1 && <HorizontalSpace />}
                    </FlexView>
                ))}
            </FlexView>
        </>
    );

    const footerMobile = buttons.map((button, index) => (
        <React.Fragment key={`button${index}`}>
            <FlexView column>
                <ResponsiveButton
                    label={button.label}
                    {...{
                        [button.buttonType]: true,
                    }}
                    onAction={button.onClick}
                    disabled={button.disabled}
                    loading={button.loading}
                />
            </FlexView>
            {index < buttons.length - 1 && <VerticalSpace />}
        </React.Fragment>
    ));

    const onWidthChange = (width: number) => {
        if (width < WindowWidth.min) {
            setScreen("mobile");
        } else {
            setScreen("desktop");
        }
    };
    return (
        <Responsive onWidthChange={onWidthChange}>
            <Form noValidate>
                <CollapsibleSegment
                    title={title}
                    children={children}
                    collapsible={collapsible}
                    onOpen={onOpen}
                    onClose={onClose}
                    open={open !== false}
                    loading={loading}
                    footer={
                        <>
                            {open !== false && screen === "desktop"
                                ? footerDesktop
                                : footerMobile}
                            {errors}
                        </>
                    }
                />
            </Form>
        </Responsive>
    );
};
