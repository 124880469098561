import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    KeyboardEvent,
    MouseEvent,
} from "react";
import { Form, Button, Label } from "semantic-ui-react";
import uniqueId from "lodash.uniqueid";

export type ButtonSelectValue = string | number;
export type ButtonSelectOption = {
    value: ButtonSelectValue;
    display: string;
};

export interface IProps {
    value: ButtonSelectValue;
    options: ButtonSelectOption[];
    label: string;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    onChange: (value: ButtonSelectValue) => void;
    onValidate?: (value: ButtonSelectValue) => void;
}

export enum VK {
    LEFT = 37,
    UP = 38,
    RIGHT = 39,
    DOWN = 40,
}

export const ButtonSelect: React.FC<IProps> = ({
    value,
    options,
    label,
    error,
    required,
    disabled,
    onChange,
    onValidate,
}) => {
    const [id] = useState(uniqueId("buttonSelect"));
    const [activeValue, setActiveValue] = useState<ButtonSelectValue>(value);
    const [selected, setSelected] = useState<number>(0);
    const [displayedError, setDisplayedError] = useState(error);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        }

        if (error) {
            timerRef.current = setTimeout(() => {
                setDisplayedError(error);
            }, 250);
        } else {
            setDisplayedError(error);
        }
    }, [error]);

    const buttonRef = useRef<Button>(null);
    const timerRef = useRef<NodeJS.Timeout | number | null>(null);
    const mount = useRef(true);

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            switch (event.keyCode) {
                case VK.UP:
                case VK.LEFT: {
                    event.preventDefault();

                    if (selected === 0) {
                        setSelected(options.length - 1);
                    } else {
                        setSelected((prevSelected) => --prevSelected);
                    }

                    break;
                }
                case VK.DOWN:
                case VK.RIGHT: {
                    event.preventDefault();

                    if (selected === options.length - 1) {
                        setSelected(0);
                    } else {
                        setSelected((prevSelected) => ++prevSelected);
                    }

                    break;
                }
            }
        },
        [selected, options.length]
    );

    useEffect(() => {
        if (mount.current) {
            return;
        }

        buttonRef.current?.focus();
    }, [selected]);

    useEffect(() => {
        if (mount.current) {
            mount.current = false;
            return;
        }

        setActiveValue(value);
        onValidate?.(value);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form.Field
            id={id}
            label={{ children: label, htmlFor: id }}
            required={required !== false}
            disabled={disabled}
            control={"div"}
            error={!!displayedError}
        >
            <Button.Group
                fluid={true}
                onKeyDown={onKeyDown}
                className={`buttonSelect${error ? " invalid" : ""}`}
            >
                {options.map(({ value: buttonValue, display }, index) => {
                    return (
                        <Button
                            key={index}
                            type={"button"}
                            value={buttonValue}
                            content={display}
                            primary={buttonValue === activeValue && !error}
                            negative={buttonValue === activeValue && !!error}
                            tabIndex={selected === index ? 0 : -1}
                            ref={selected === index ? buttonRef : undefined}
                            onClick={(
                                _e: MouseEvent<HTMLButtonElement>,
                                { value: newValue }
                            ) => {
                                if (
                                    required === false &&
                                    newValue === activeValue
                                ) {
                                    newValue = "";
                                }

                                setSelected(index);
                                setActiveValue(newValue);
                                onChange(newValue);
                                onValidate?.(newValue);
                            }}
                        />
                    );
                })}
            </Button.Group>
            {displayedError && (
                <Label basic pointing="above" prompt>
                    {displayedError}
                </Label>
            )}
        </Form.Field>
    );
};
