import React from "react";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { IMenuItem } from "./MainMenuItem";

interface IProps {
    items: IMenuItem[];
}

export const MainMenuUserItem: React.FC<IProps> = ({ children, items }) => {
    const history = useHistory();

    return (
        <Menu.Menu position="right">
            <Dropdown
                className="menu-item"
                item
                basic
                simple
                trigger={
                    <Icon
                        name="user circle"
                        size="big"
                        inverted
                        fitted
                        data-testid="user-menu"
                    />
                }
            >
                <Dropdown.Menu>
                    {items.map((items) => (
                        <Dropdown.Item
                            key={items.text + items.link}
                            text={items.text}
                            onClick={() => history.push(items.link)}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Menu>
    );
};
