import React, { useState } from "react";
import { TranslateFunction } from "./I18nTypes";
import { ITableProps } from "./Table";
import { Detail, IDetailProps } from "./Detail";
import { CollapsibleSegment } from "./CollapsibleSegment";
import { ResponsiveButton } from "./ResponsiveButton";
import { VerticalSpace } from "./VerticalSpace";
import { Modal } from "semantic-ui-react";
import { ITableDetailProps } from "./TableDetail";
import { HorizontalSpace } from "./HorizontalSpace";

interface IProps {
    title: string;
    canCreate?: boolean;
    edited?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
    children:
        | React.ReactElement<ITableProps>
        | [React.ReactElement<ITableProps>, React.ReactElement<IDetailProps>];
    translate: TranslateFunction;
    loading?: boolean;
    isSaving?: boolean;
}

export const TableSegment: React.FC<IProps> = ({
    title,
    children,
    edited,
    onSave,
    onCancel,
    canCreate,
    translate: t,
    loading,
    isSaving,
}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    let modal: React.ReactElement | null = null;

    const childArray = React.Children.toArray(children) as React.ReactElement[];

    // if (!childArray[0] || childArray[0].type !== Table) {
    //     throw new Error("Unexpected subcomponent at index 0: Requires Table");
    // }

    // if (childArray[1] && childArray[1].type !== TableDetail) {
    //     throw new Error(
    //         "Unexpected subcomponent at index 1: Requires TableDetail"
    //     );
    // }

    const table = childArray[0] as React.ReactElement<ITableProps>;
    if (childArray[1]) {
        const originalDetail = childArray[1] as React.ReactElement<ITableDetailProps>;
        const detail = (
            <Detail
                {...originalDetail.props}
                edited={true}
                collapsible={false}
                onCancel={() => {
                    originalDetail.props.onCancel?.();
                    setModalOpen(false);
                }}
                onSave={async () => {
                    const success = await originalDetail.props.onSave?.();
                    if (success) {
                        setModalOpen(false);
                    }
                }}
            />
        );

        modal =
            canCreate !== false ? (
                <Modal
                    data-testid="modal"
                    basic={true}
                    trigger={
                        <ResponsiveButton
                            label={t("app.create")}
                            fluid={false}
                            onAction={() => setModalOpen(true)}
                        />
                    }
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    open={modalOpen}
                >
                    <Modal.Content>{detail}</Modal.Content>
                </Modal>
            ) : null;
    }

    const buttons = edited ? (
        <>
            <React.Fragment key={`button0`}>
                <ResponsiveButton
                    label={t("app.save")}
                    onAction={onSave!}
                    primary={true}
                    fluid={false}
                    loading={isSaving}
                />

                <HorizontalSpace />

                <ResponsiveButton
                    label={t("app.cancel")}
                    secondary={true}
                    onAction={onCancel!}
                    fluid={false}
                    disabled={isSaving}
                />
            </React.Fragment>
        </>
    ) : undefined;

    return (
        <CollapsibleSegment
            title={title}
            collapsible={true}
            open={true}
            footer={buttons}
            loading={loading}
        >
            {!loading && table}
            {modal ? (
                <>
                    <VerticalSpace />
                    {modal}
                </>
            ) : undefined}
        </CollapsibleSegment>
    );
};
