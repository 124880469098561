import React from "react";
import { Loader } from "semantic-ui-react";

export interface ILoaderProps {
    active: boolean;
}

export const Loading: React.FC<ILoaderProps> = ({ active }) => {
    return <Loader inverted active={active} />;
};
