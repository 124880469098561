import React, { useState } from "react";
import { ITableProps, Table } from "./Table";
import { IDetailProps, Detail } from "./Detail";
import { PrimaryButton } from "./PrimaryButton";
import {
    Button,
    Dropdown as UIDropdown,
    Modal,
    Segment,
} from "semantic-ui-react";
import FlexView from "react-flexview";
import { TranslateFunction } from "./I18nTypes";
import { ITableDetailProps, TableDetail } from "./TableDetail";
import { VerticalSpace } from "./VerticalSpace";
import { HorizontalSpace } from "./HorizontalSpace";
import { DetailBase, IDetailBaseButton } from "./DetailBase";
import { Dropdown, DropdownOption, DropdownValue } from "./Dropdown";
import { extractComponent } from "./extractComponent";

interface IProps {
    title: string;
    canCreate?: boolean;
    importDialog?: {
        onSave?: () => Promise<boolean>;
        onCancel?: () => void;
        onChange: (value: React.ReactText | undefined) => void;
        onFocus?: () => void;
        onValidate?: (value: React.ReactText | undefined) => void;
        options: DropdownOption[];
        value: DropdownValue;
        hasPermission?: boolean;
    };
    translate: TranslateFunction;
    children:
        | React.ReactElement<ITableProps>
        | [React.ReactElement<ITableProps>, React.ReactElement<IDetailProps>];
    loading?: boolean;
}

export const TableView: React.FC<IProps> = ({
    importDialog,
    title,
    children,
    canCreate,
    translate: t,
    loading,
}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalImportOpen, setModalImportOpen] = useState<boolean>(false);

    let modalCreate: React.ReactElement | null = null;

    let tableComponent: React.ReactElement<ITableProps> | null = null;
    let detailComponent: React.ReactElement<ITableDetailProps> | null = null;

    const childArray = React.Children.toArray(children) as React.ReactElement[];
    childArray.forEach((child) => {
        const extractedComponents = extractComponent(
            child,
            {
                detail: TableDetail,
                table: Table,
            },
            1
        );
        if (extractedComponents.detail) {
            detailComponent = extractedComponents.detail as React.ReactElement<ITableDetailProps>;
        }
        if (extractedComponents.table) {
            tableComponent = extractedComponents.table as React.ReactElement<ITableProps>;
        }
    });

    if (!tableComponent) {
        throw new Error("Missing table component");
    }

    const table = tableComponent as React.ReactElement<ITableProps>;
    if (detailComponent) {
        const detail = detailComponent as React.ReactElement<ITableDetailProps>;
        const detailCreate = (
            <Detail
                {...detail.props}
                edited={true}
                collapsible={false}
                onCancel={() => {
                    detail.props.onCancel?.();
                    setModalOpen(false);
                }}
                onSave={async () => {
                    const success = await detail.props.onSave?.();
                    if (success) {
                        setModalOpen(false);
                    }
                }}
            />
        );

        modalCreate =
            canCreate !== false ? (
                <Modal
                    data-testid="modal"
                    basic={true}
                    trigger={
                        <PrimaryButton
                            label={t("app.create")}
                            fluid={false}
                            onAction={() => setModalOpen(true)}
                        />
                    }
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    open={modalOpen}
                >
                    <Modal.Content>{detailCreate}</Modal.Content>
                </Modal>
            ) : null;
    }

    const listboxMenu = importDialog?.hasPermission && (
        <UIDropdown
            trigger={<Button icon="ellipsis vertical" />}
            icon={null}
            direction="left"
        >
            <UIDropdown.Menu>
                <Modal
                    data-testid="importModal"
                    basic={true}
                    trigger={
                        <UIDropdown.Item
                            onClick={() => setModalImportOpen(true)}
                            text={t("app.import")}
                        ></UIDropdown.Item>
                    }
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    open={modalImportOpen}
                >
                    <Modal.Content>
                        <DetailImport
                            translate={t}
                            collapsible={false}
                            title={t("app.import")}
                            onCancel={() => {
                                importDialog.onCancel?.();
                                setModalImportOpen(false);
                            }}
                            onSave={async () => {
                                const imported = await importDialog.onSave?.();

                                if (imported !== false) {
                                    setModalImportOpen(false);
                                }
                            }}
                        >
                            <Dropdown
                                value={importDialog.value}
                                required
                                label={t("app.source")}
                                options={importDialog.options}
                                onChange={importDialog.onChange}
                                onValidate={importDialog.onValidate}
                                onFocus={importDialog.onFocus}
                            />
                        </DetailImport>
                    </Modal.Content>
                </Modal>
            </UIDropdown.Menu>
        </UIDropdown>
    );

    return (
        <>
            <FlexView>
                <FlexView hAlignContent={"left"}>
                    <h1>{title.toLocaleUpperCase()}</h1>
                </FlexView>
                <FlexView hAlignContent={"right"} marginLeft={"auto"}>
                    {modalCreate}
                </FlexView>
                <FlexView>
                    {importDialog?.hasPermission && canCreate !== false && (
                        <HorizontalSpace small />
                    )}
                    {listboxMenu}
                </FlexView>
            </FlexView>
            <VerticalSpace small />
            <Segment className={"tableSegment"} loading={loading}>
                {table}
            </Segment>
        </>
    );
};

export const TestDetailFromTableView: React.FC<{
    translate: TranslateFunction;
    children:
        | React.ReactElement<ITableProps>
        | [React.ReactElement<ITableProps>, React.ReactElement<IDetailProps>];
}> = ({ children, translate: t }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    let detailComponent: React.ReactElement<ITableDetailProps> | null = null;

    const childArray = React.Children.toArray(children) as React.ReactElement[];
    childArray.forEach((child) => {
        const extractedComponents = extractComponent(
            child,
            {
                detail: TableDetail,
            },
            1
        );
        if (extractedComponents.detail) {
            detailComponent = extractedComponents.detail as React.ReactElement<ITableDetailProps>;
        }
    });

    if (detailComponent === null) {
        throw new Error("Missing Detail component");
    }

    const detail = detailComponent as React.ReactElement<ITableDetailProps>;

    return (
        <FlexView>
            <PrimaryButton
                label={t("app.create")}
                fluid={false}
                onAction={() => setModalOpen(true)}
            />
            {modalOpen && (
                <Modal
                    data-testid="modal"
                    basic={true}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    open={true}
                >
                    <Modal.Content>
                        <Detail
                            {...detail.props}
                            edited={true}
                            collapsible={false}
                            onCancel={() => {
                                detail.props.onCancel?.();
                                setModalOpen(false);
                            }}
                            onSave={async () => {
                                const success = await detail.props.onSave?.();
                                if (success) {
                                    setModalOpen(false);
                                }
                            }}
                        />
                    </Modal.Content>
                </Modal>
            )}
        </FlexView>
    );
};

const DetailImport: React.FC<IDetailProps> = ({
    children,
    title,
    edited,
    open,
    onOpen,
    onClose,
    onCancel,
    onSave,
    collapsible = true,
    translate: t,
    ...props
}) => {
    const buttons: IDetailBaseButton[] = [];

    buttons.push(
        { label: t("app.import"), buttonType: "primary", onClick: onSave! },
        { label: t("app.cancel"), buttonType: "secondary", onClick: onCancel! }
    );

    return (
        <DetailBase
            buttons={buttons}
            {...{
                children,
                title,
                edited,
                open,
                onOpen,
                onClose,
                collapsible,
                translate: t,
            }}
            {...props}
        />
    );
};
