import React from "react";
import { TranslateFunction } from "./I18nTypes";
import { IDetailProps, Detail } from "./Detail";

import FlexView from "react-flexview";
import { Dropdown, Button } from "semantic-ui-react";
import { VerticalSpace } from "./VerticalSpace";

interface IProps {
    title: string;
    children:
        | React.ReactElement<IDetailProps>
        | React.ReactElement<IDetailProps>[];
    onDelete: () => void;
    canDelete?: boolean;
    translate: TranslateFunction;
}

export const DetailView: React.FC<IProps> = ({
    title,
    children,
    onDelete,
    canDelete,
    translate: t,
}) => {
    const childArray = React.Children.toArray(children) as React.ReactElement[];

    childArray.forEach((child, index) => {
        if (child.type !== Detail) {
            throw new Error(
                `Unexpected subcomponent at index ${index}: Requires Detail`
            );
        }
    });

    return (
        <>
            <FlexView>
                <FlexView hAlignContent={"left"}>
                    <h1>{title}</h1>
                </FlexView>
                <FlexView hAlignContent={"right"} marginLeft={"auto"}>
                    {canDelete !== false && (
                        <Dropdown
                            trigger={<Button icon="ellipsis vertical" />}
                            icon={null}
                            direction="left"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={onDelete}>
                                    {t("key.delete")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </FlexView>
            </FlexView>
            <VerticalSpace small />
            {childArray.map((child, index) => {
                return (
                    <div key={index}>
                        {index > 0 && <VerticalSpace key={index} />}
                        {child}
                    </div>
                );
            })}
        </>
    );
};
