import React from "react";
import { ResponsiveButton } from "./ResponsiveButton";

interface IProps {
    label: string;
    onAction: () => void;
    fluid?: boolean;
    disabled?: boolean;
    loading?: boolean;
}

export const PrimaryButton: React.FC<IProps> = ({ children, ...props }) => {
    return <ResponsiveButton primary {...props} />;
};
