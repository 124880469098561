import React, { useState, useEffect, useRef } from "react";
import moment, { Moment } from "moment";
import { Form, FormField, Label } from "semantic-ui-react";
import MaskInput from "react-maskinput";
import uniqueId from "lodash.uniqueid";
import { TranslateDateFormatFunction } from "./I18nTypes";
import { DateInputPicker, ICalendarPickerApi } from "./DateInputPicker";

interface IProps {
    value?: Moment;
    label: string;
    required?: boolean;
    minDate?: Moment;
    maxDate?: Moment;
    onChange: (value: Moment | undefined) => void;
    onValidate?: (value: string) => void;
    format?: string;
    displayFormat?: string;
    error?: string;
    disabled?: boolean;
    translateDateFormat: TranslateDateFormatFunction;
}

const unallowedChars = /[^0-9.,]/g;
const decimalChars = /[.,]/g;

interface ISelection {
    start: number;
    end: number;
}

enum CharTypes {
    USER = 1,
    CHAR = 2,
    MASK = 3,
}

interface IChar {
    char: string;
    type: CharTypes;
}

const reformat = (
    value: IChar[],
    char: string,
    selection: ISelection
): { value: IChar[]; selection: ISelection } => {
    const prevValue = value[selection.start - 1];
    const curValue = value[selection.start];
    if (selection.start === 0) {
        if (/[0123]/.test(char)) {
            curValue.char = char;
            curValue.type = CharTypes.USER;
            if (char === "3" && /[2-9]/.test(value[1].char)) {
                value[1].type = CharTypes.MASK;
                value[1].char = "D";
            }
            selection.start++;
        } else if (/[4-9]/.test(char)) {
            curValue.char = "0";
            curValue.type = CharTypes.USER;
            selection.start += 1;
            return reformat(value, char, selection);
        }
    } else if (selection.start === 1) {
        if (prevValue.type === CharTypes.MASK) {
            if (/[0123]/.test(char)) {
                selection.start--;
                return reformat(value, char, selection);
            } else if (/[4-9]/.test(char)) {
                prevValue.char = "0";
                prevValue.type = CharTypes.USER;
                return reformat(value, char, selection);
            }
        } else if (prevValue.char === "0") {
            if (/[1-9]/.test(char)) {
                prevValue.char = "0";
                prevValue.type = CharTypes.USER;
                curValue.char = char;
                curValue.type = CharTypes.USER;
                selection.start++;
            }
        } else {
            if (/[0-9]/.test(char)) {
                if (prevValue.char !== "3" || /[01]/.test(char)) {
                    curValue.char = char;
                    curValue.type = CharTypes.USER;
                    selection.start++;
                }
            } else if (char === "." && curValue.type === CharTypes.MASK) {
                curValue.type = prevValue.type;
                curValue.char = prevValue.char;
                prevValue.char = "0";
                prevValue.type = CharTypes.USER;
                selection.start += 2;
            }
        }
    } else if (selection.start === 2) {
        if (char === ".") {
            selection.start++;
        } else {
            selection.start++;
            return reformat(value, char, selection);
        }
    } else if (selection.start === 3) {
        if (/[01]/.test(char)) {
            curValue.char = char;
            curValue.type = CharTypes.USER;
            if (char === "1" && /[3-9]/.test(value[4].char)) {
                value[4].type = CharTypes.MASK;
                value[4].char = "M";
            }
            selection.start++;
        } else if (/[2-9]/.test(char)) {
            curValue.char = "0";
            curValue.type = CharTypes.USER;
            selection.start += 1;
            return reformat(value, char, selection);
        }
    } else if (selection.start === 4) {
        if (prevValue.type === CharTypes.MASK) {
            if (/[01]/.test(char)) {
                selection.start--;
                return reformat(value, char, selection);
            } else if (/[2-9]/.test(char)) {
                prevValue.char = "0";
                prevValue.type = CharTypes.USER;
                return reformat(value, char, selection);
            }
        } else if (prevValue.char === "0") {
            if (/[1-9]/.test(char)) {
                prevValue.char = "0";
                prevValue.type = CharTypes.USER;
                curValue.char = char;
                curValue.type = CharTypes.USER;
                selection.start++;
            }
        } else {
            if (/[0-9]/.test(char)) {
                if (prevValue.char === "1" && /[012]/.test(char)) {
                    curValue.char = char;
                    curValue.type = CharTypes.USER;
                    selection.start++;
                }
            } else if (char === "." && curValue.type === CharTypes.MASK) {
                curValue.type = prevValue.type;
                curValue.char = prevValue.char;
                prevValue.char = "0";
                prevValue.type = CharTypes.USER;
                selection.start += 2;
            }
        }
    } else if (selection.start === 5) {
        if (char === ".") {
            selection.start++;
        } else {
            selection.start++;
            return reformat(value, char, selection);
        }
    } else if (selection.start === 6) {
        const nextValue = value[selection.start + 1];
        if (/[12]/.test(char)) {
            curValue.char = char;
            curValue.type = CharTypes.USER;
            selection.start++;
        } else if (/[034]/.test(char)) {
            curValue.char = "2";
            curValue.type = CharTypes.USER;
            nextValue.char = "0";
            nextValue.type = CharTypes.USER;
            selection.start += 2;
            return reformat(value, char, selection);
        } else if (/[5-9]/.test(char)) {
            curValue.char = "1";
            curValue.type = CharTypes.USER;
            nextValue.char = "9";
            nextValue.type = CharTypes.USER;
            selection.start += 2;
            return reformat(value, char, selection);
        }
    } else if (selection.start === 7) {
        const nextValue = value[selection.start + 1];
        if (prevValue.type === CharTypes.MASK) {
            selection.start--;
            return reformat(value, char, selection);
        }
        if (prevValue.char === "1") {
            if (char === "9") {
                curValue.char = char;
                curValue.type = CharTypes.USER;
                selection.start++;
            } else {
                prevValue.char = "2";
                prevValue.type = CharTypes.USER;
                curValue.char = "0";
                curValue.type = CharTypes.USER;
                nextValue.char = "1";
                nextValue.type = CharTypes.USER;
                selection.start += 2;
                return reformat(value, char, selection);
            }
        } else if (prevValue.char === "2") {
            if (char === "0") {
                curValue.char = char;
                curValue.type = CharTypes.USER;
                selection.start++;
            } else {
                prevValue.char = "2";
                prevValue.type = CharTypes.USER;
                curValue.char = "0";
                curValue.type = CharTypes.USER;
                nextValue.char = "2";
                nextValue.type = CharTypes.USER;
                selection.start += 2;
                return reformat(value, char, selection);
            }
        }
    } else if (selection.start === 8) {
        if (
            value[6].type === CharTypes.MASK ||
            value[7].type === CharTypes.MASK
        ) {
            selection.start -= 2;
            return reformat(value, char, selection);
        }
        if (/[0-9]/.test(char)) {
            curValue.char = char;
            curValue.type = CharTypes.USER;
            selection.start++;
        }
    } else if (selection.start === 9) {
        if (
            value[6].type === CharTypes.MASK ||
            value[7].type === CharTypes.MASK ||
            value[8].type === CharTypes.MASK
        ) {
            selection.start -= 3;
            return reformat(value, char, selection);
        }
        if (/[0-9]/.test(char)) {
            curValue.char = char;
            curValue.type = CharTypes.USER;
            selection.start++;
        }
    }

    selection.end = selection.start;
    return { value, selection };
};

export const DateInput: React.FC<IProps> = ({
    format = "DD.MM.YYYY",
    value: propsValue,
    required,
    onValidate,
    error,
    disabled,
    translateDateFormat: tDF,
    ...props
}) => {
    const [id] = useState(uniqueId("input_"));
    const [focused, setFocused] = useState(false);
    const [internalValue, setInternalValue] = useState(
        propsValue ? propsValue.format(format) : format
    );
    const [pickerOpen, setPickerOpen] = useState(false);
    const ref = useRef<HTMLInputElement | null>(null);

    const pickerRef = useRef(pickerOpen);
    pickerRef.current = pickerOpen;

    const pickerApiRef = useRef<ICalendarPickerApi | null>(null);

    const timerRef = useRef<NodeJS.Timeout | number | null>(null);
    const [displayedError, setDisplayedError] = useState(error);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        }

        const newError = focused ? "" : error;

        if (newError) {
            timerRef.current = setTimeout(() => {
                setDisplayedError(newError);
            }, 250);
        } else {
            setDisplayedError(newError);
        }
    }, [error, focused]);

    useEffect(() => {
        setInternalValue(propsValue ? propsValue.format(format) : format);
    }, [propsValue, format]);

    const reformatInput = (params: {
        value: string | IChar[];
        input?: string;
        selection: ISelection;
    }) => {
        const charOrMask = (ch: string, mask: string) => {
            const ret = {
                char: ch,
                type: ch === mask ? CharTypes.MASK : CharTypes.USER,
            };
            return ret;
        };

        if (!(params.value instanceof Array)) {
            const str = params.value;
            const v = str
                ? [
                      charOrMask(str.charAt(0), "D"),
                      charOrMask(str.charAt(1), "D"),
                      { char: ".", type: CharTypes.CHAR },
                      charOrMask(str.charAt(3), "M"),
                      charOrMask(str.charAt(4), "M"),
                      { char: ".", type: CharTypes.CHAR },
                      charOrMask(str.charAt(6), "Y"),
                      charOrMask(str.charAt(7), "Y"),
                      charOrMask(str.charAt(8), "Y"),
                      charOrMask(str.charAt(9), "Y"),
                  ]
                : [];
            let displayValue = v.map((c) => c.char).join("");

            return {
                value: v,
                visibleValue: displayValue,
                maskedValue: displayValue,
                selection: params.selection,
            };
        }

        const input = params.input || "";
        const value = params.value as IChar[];
        const filteredInput = input
            .replace(unallowedChars, "")
            .replace(decimalChars, ".");

        for (
            let i = params.selection.start;
            i < params.selection.end && i < 10;
            i++
        ) {
            if (value[i].type === CharTypes.USER) {
                value[i] = { char: format.charAt(i), type: CharTypes.MASK };
            }
        }

        let returnValue = {
            value,
            selection: params.selection,
        };

        if (params.input) {
            for (let i = 0; i < filteredInput.length; i++) {
                returnValue = reformat(
                    returnValue.value,
                    filteredInput[i],
                    returnValue.selection
                );
            }
        } else {
            if (returnValue.selection.start === returnValue.selection.end - 1) {
                if (
                    ref.current!.selectionStart === returnValue.selection.start
                ) {
                    returnValue.selection.start = returnValue.selection.end;
                } else {
                    returnValue.selection.end = returnValue.selection.start;
                }
            } else {
                returnValue.selection.end = returnValue.selection.start;
            }
        }

        let displayValue = returnValue.value.map((c) => c.char).join("");

        return {
            value: returnValue.value,
            visibleValue: displayValue,
            maskedValue: displayValue,
            selection: returnValue.selection,
        };
    };

    const onValueChange = (params: { maskedValue: string; value: string }) => {
        const value = params.maskedValue;
        setInternalValue(value);
        onValidate?.(value);

        if (value === format || value === "") {
            props.onChange(undefined);
        } else if (moment(value, format, true).isValid()) {
            props.onChange(moment(value, format));
        }
    };

    const onKeyDown = (e: Event) => {
        const ke = e as KeyboardEvent;

        if (ke.key === "Enter") {
            validate(internalValue);
            setPickerOpen(false);
        }
        if (pickerOpen) {
            if (ke.key === "ArrowUp") {
                pickerApiRef.current!.moveUp();
                e.preventDefault();
                e.stopPropagation();
            }
            if (ke.key === "ArrowDown") {
                pickerApiRef.current!.moveDown();
                e.preventDefault();
                e.stopPropagation();
            }
            if (ke.key === "ArrowLeft") {
                pickerApiRef.current!.moveLeft();
                e.preventDefault();
                e.stopPropagation();
            }
            if (ke.key === "ArrowRight") {
                pickerApiRef.current!.moveRight();
                e.preventDefault();
                e.stopPropagation();
            }
        }
    };

    const onClick = (e: MouseEvent) => {
        if (pickerRef.current) {
            setPickerOpen(false);
        } else {
            setPickerOpen(true);
        }
        e.stopPropagation();
        e.preventDefault();
    };

    const onBlur = (_e: React.FocusEvent) => {
        validate(internalValue);
        setFocused(false);
    };

    const validate = (value: string) => {
        if (value === "DD.MM.YYYY" || value === "") {
            if (propsValue !== undefined) {
                props.onChange(undefined);
            }

            onValidate?.("");
            return;
        }
        let dateNum = 0;
        const date = value.substring(0, 2);
        if (date !== "DD") {
            dateNum = parseInt(date.replace(/D/g, ""), 10);
        }
        let monthNum = 0;
        const month = value.substring(3, 5);
        if (month !== "MM") {
            monthNum = parseInt(month.replace(/M/g, ""), 10);
        }
        let yearNum = 0;
        const year = value.substring(6, 10);
        if (year !== "YYYY") {
            yearNum = parseInt(year.replace(/Y/g, ""), 10);
            if (yearNum >= 50 && yearNum <= 99) {
                yearNum += 1900;
            }
            if (yearNum >= 0 && yearNum <= 49) {
                yearNum += 2000;
            }
        }

        const m = moment([yearNum, monthNum - 1, dateNum]);
        if (
            m.isValid() &&
            m.isSameOrAfter(moment([1900, 0, 1])) &&
            m.isBefore(moment([2100, 0, 1])) &&
            !m.isSame(propsValue)
        ) {
            props.onChange(m);

            onValidate?.(m.format(format));
        } else {
            const newInternalValue = propsValue
                ? propsValue.format(format)
                : format;
            setInternalValue(newInternalValue);
            onValidate?.(newInternalValue);
        }
    };

    useEventListener("keydown", onKeyDown, ref.current);

    return (
        <Form.Field
            required={required}
            control={FormField}
            className={"dateInput"}
            disabled={disabled}
            error={!!displayedError && !focused && !pickerOpen}
        >
            <label htmlFor={id} className={"field"}>
                {props.label}
            </label>
            <MaskInput
                onFocus={(_e: React.FocusEvent) => {
                    setFocused(true);
                }}
                onBlur={onBlur}
                value={focused || internalValue !== format ? internalValue : ""}
                reformat={reformatInput}
                onValueChange={onValueChange}
                getReference={(el) => {
                    el.setAttribute("id", id);
                    el.addEventListener("click", onClick);
                    ref.current = el;
                }}
            ></MaskInput>
            {displayedError && !focused && !pickerOpen && (
                <Label basic pointing="above" prompt>
                    {displayedError}
                </Label>
            )}
            <DateInputPicker
                translateDateFormat={tDF}
                open={pickerOpen}
                inputRef={ref}
                onClose={() => {
                    setPickerOpen(false);
                }}
                value={propsValue}
                onPick={(date: moment.Moment) => {
                    const formatted = date.format(format);
                    setInternalValue(formatted);
                    setPickerOpen(false);
                    validate(formatted);
                }}
                getApi={(api) => (pickerApiRef.current = api)}
                onSelection={(date) => {
                    props.onChange(date);
                }}
            />
        </Form.Field>
    );
};

function useEventListener<K extends keyof HTMLElementEventMap>(
    eventName: K,
    handler: EventListener,
    element: null | HTMLElement
) {
    const savedHandler = useRef<EventListener | null>();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;
            const eventListener = (event: Event) =>
                savedHandler.current!(event);
            element?.addEventListener(eventName, eventListener);
            return () => {
                element?.removeEventListener(eventName, eventListener);
            };
        },

        [eventName, element] // Re-run if eventName or element changes
    );
}
