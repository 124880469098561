import React from "react";
import { Menu } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

export interface IMenuItem {
    text: string;
    link: string;
}

interface IProps extends IMenuItem {}

export const MainMenuItem: React.FC<IProps> = ({ children, text, link }) => {
    const history = useHistory();

    return (
        <Menu.Item
            className="menu-item"
            content={text}
            onClick={() => history.push(link!)}
        />
    );
};
