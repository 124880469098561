import React from "react";

interface IProps {
    children: React.ReactNode;
}

interface ReactFCMainLayout extends React.FC<IProps> {
    Header: React.FC<IProps>;
    Body: React.FC<IProps>;
}

export const MainLayout: ReactFCMainLayout = ({ children }) => {
    if (React.Children.count(children) === 2) {
        const childArray = React.Children.toArray(
            children
        ) as React.ReactElement[];
        if (childArray[0]?.type !== MainLayoutHeader) {
            throw new Error(
                "Unexpected subcomponent at index 0: Requires MainLayoutHeader"
            );
        }
        if (childArray[1]?.type !== MainLayoutBody) {
            throw new Error(
                "Unexpected subcomponent at index 1: Requires MainLayoutBody"
            );
        }
    } else {
        throw new Error(
            "All mandatory subcomponents must be present, each exactly once, with no additional subcomponents"
        );
    }

    return <div className={"mainLayout"}>{children}</div>;
};

const MainLayoutHeader: React.FC<IProps> = ({ children }) => {
    return <div className={"mainLayoutHeader"}>{children}</div>;
};

const MainLayoutBody: React.FC<IProps> = ({ children }) => {
    return <div className={"mainLayoutBody"}>{children}</div>;
};

MainLayout.Header = MainLayoutHeader;
MainLayout.Body = MainLayoutBody;
