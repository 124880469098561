import React, {
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
    useCallback,
} from "react";
import { AgGridReact as AgGrid } from "ag-grid-react";
import {
    ColDef,
    RowClickedEvent,
    PostProcessPopupParams,
    CellValueChangedEvent,
    CellClassParams,
    GridReadyEvent,
    GridApi,
    RowNode,
    Column,
    CellClickedEvent,
    ICellEditorParams,
    ValueFormatterParams,
    ValueSetterParams,
} from "ag-grid-community";
import { Checkbox, Icon, SemanticICONS } from "semantic-ui-react";
import { formatValue, NumberField } from "./NumberField";
import { Dropdown, DropdownOption, DropdownValue } from "./Dropdown";
import moment, { Moment } from "moment";
import { NavLink } from "react-router-dom";
import { DropdownGroup, DropdownGroupOption } from "./DropdownGroup";

export type TableColumnType =
    | "text"
    | "number"
    | "checkbox"
    | "dropdown"
    | "action"
    | "date"
    | "textLink"
    | "dropdownGroup";

export type CellValue = string | number | undefined;

export interface ITableProps {
    children:
        | React.ReactElement<IColumnProps>
        | React.ReactElement<IColumnProps>[];
    data: object[];
    canCreate?: boolean;
    onRowClick?: (row: object) => void;
    onCellChange?: (row: object, column: string, value: CellValue) => void;
    onCheckboxChange?: (row: object, column: string, value: boolean) => void;
    onAction?: (rowIndex: number, column: string, row: object) => void;
    onTableReady?: () => void;
    colWidth?: number;
    noRowsMessage: string;
    defaultColumnDefinition?: ColDef;
    disabled?: boolean;
    sizeColumnsToFit?: boolean;
}

interface IColumnProps {
    type: TableColumnType;
    column: string;
    children: string;
    onValidate?: (value: CellValue) => string;
    sort?: string;
    width?: number;
    pinned?: boolean;
    flex?: number;
    editable?: boolean;
    disableNavigableFocus?: boolean;
}

interface ITypedColumnProps extends Omit<IColumnProps, "type"> {}

interface INumberColumnProps extends ITypedColumnProps {
    max?: number;
    min?: number;
    decimalCount?: number;
    disabledFormat?: boolean;
}

interface IDropdownColumnProps extends ITypedColumnProps {
    values: DropdownOption[];
}

interface IDropdownGroupColumnProps extends ITypedColumnProps {
    values: DropdownGroupOption[];
    search?: boolean;
}

interface IActionColumnProps
    extends Omit<
        ITypedColumnProps,
        "children" | "onValidate" | "sort" | "width" | "flex" | "editable"
    > {
    icon: SemanticICONS;
    tooltip?: string;
}

interface IDateColumnProps
    extends Omit<ITypedColumnProps, "onValidate" | "editable"> {
    onValidate?: (value: Moment) => string;
}

interface ITextLinkColumnProps extends ITypedColumnProps {}

interface ReactFCTable extends React.FC<ITableProps> {
    Column: React.FC<IColumnProps>;
    TextColumn: React.FC<ITypedColumnProps>;
    NumberColumn: React.FC<INumberColumnProps>;
    CheckboxColumn: React.FC<ITypedColumnProps>;
    DropdownColumn: React.FC<IDropdownColumnProps>;
    ActionColumn: React.FC<IActionColumnProps>;
    DateColumn: React.FC<IDateColumnProps>;
    TextLinkColumn: React.FC<ITextLinkColumnProps>;
    DropdownGroupColumn: React.FC<IDropdownGroupColumnProps>;
}

export const Table: ReactFCTable = ({
    children,
    data,
    canCreate,
    onRowClick,
    onCellChange,
    onCheckboxChange,
    onAction,
    onTableReady,
    colWidth,
    noRowsMessage,
    defaultColumnDefinition,
    disabled,
    sizeColumnsToFit,
}) => {
    const refGridApi = useRef<GridApi | null>(null);
    const columnDefinitions: ColDef[] = [];
    const columnValidations: {
        [column: string]: ((value: CellValue) => string) | undefined;
    } = {};

    const refData = useRef<object[]>(
        data
            .map((row) => ({ ...row, isRowCreator: false }))
            .concat(canCreate ? [{ isRowCreator: true }] : [])
    );

    const onCheckboxChangeRef = useRef(onCheckboxChange);
    onCheckboxChangeRef.current = onCheckboxChange;

    let popup = useRef<HTMLElement | undefined>(undefined);

    const defaultColDef: ColDef = defaultColumnDefinition
        ? {
              ...defaultColumnDefinition,
              suppressMovable: true,
          }
        : {
              sortable: true,
              filter: true,
              sort: "asc",
              filterParams: {
                  buttons: ["reset"],
              },
              singleClickEdit: true,
              suppressMovable: true,
          };

    const typeColDefMap: Map<TableColumnType, ColDef> = new Map([
        [
            "text",
            {
                comparator: (valueA: string, valueB: string) => {
                    if (valueA === undefined || valueB === undefined) {
                        return 0;
                    }

                    const a = valueA.toLowerCase();
                    const b = valueB.toLowerCase();
                    if (a === b) {
                        return 0;
                    }
                    return a > b ? 1 : -1;
                },
            },
        ],
        [
            "number",
            {
                cellClass: "numberFieldCell",
                editable: true,
                cellRenderer: "numberRenderer",
            },
        ],
        [
            "checkbox",
            {
                cellClass: "checkboxCell",
                editable: false,
                cellRenderer: "checkboxRenderer",
                onCellClicked: (event: CellClickedEvent) => {
                    const { node, column } = event;

                    const isDisabled: boolean = column.getColDef()
                        .cellRendererParams!.disabled;

                    const currentChecked: boolean | undefined =
                        node.data[column.getColId()];

                    if (!isDisabled && currentChecked !== undefined) {
                        node.setDataValue(column.getColId(), !currentChecked);

                        onCheckboxChangeRef?.current!(
                            node.data,
                            column.getColId(),
                            !currentChecked
                        );
                    }
                },
            },
        ],
        ["dropdown", {}],
        [
            "action",
            {
                width: 50,
                editable: false,
                sortable: false,
                filter: false,
                headerClass: "no-hover",
                cellClass: "no-border",
                cellClassRules: undefined,
                cellRenderer: "actionRenderer",
                onCellClicked: (event: CellClickedEvent) => {
                    const { rowIndex, column, node } = event;

                    if (
                        node.data[column.getColId()] &&
                        !node.data.isRowCreator &&
                        rowIndex !== null
                    ) {
                        onActionClick(rowIndex, column.getColId(), node);
                    }
                },
            },
        ],
        [
            "textLink",
            {
                cellRenderer: "textLinkRenderer",
            },
        ],
        ["dropdownGroup", {}],
    ]);

    const processTableData = useCallback(() => {
        const updateRows: object[] = [];
        const newRows: object[] = [];
        const deleteRows: object[] = [];

        const rowNodeMap = {};
        refGridApi.current!.forEachNode((rowNode) => {
            if (rowNode.id) rowNodeMap[rowNode.id] = true;
        });

        const rows = data
            .map((row) => ({ ...row, isRowCreator: false }))
            .concat(canCreate ? [{ isRowCreator: true }] : []);

        rows.forEach((row) => {
            const rowNode = refGridApi.current!.getRowNode(row["id"] as string);

            if (rowNode) {
                updateRows.push(row);
            } else {
                newRows.push(row);
            }

            rowNodeMap[row["id"]] = false;
        });

        for (const id in rowNodeMap) {
            if (rowNodeMap[id]) {
                const rowNode = refGridApi.current!.getRowNode(id as string);
                if (rowNode) {
                    deleteRows.push(rowNode);
                }
            }
        }

        refGridApi.current!.applyTransaction({
            update: updateRows,
            add: newRows,
            remove: deleteRows,
        });
    }, [data, canCreate]);

    const onGridReady = (event: GridReadyEvent) => {
        refGridApi.current = event.api;
        onGridSizeChanged(event);
        processTableData();
        onTableReady?.();
    };

    const onGridSizeChanged = (event: GridReadyEvent) => {
        if (sizeColumnsToFit) {
            event.api.sizeColumnsToFit();
        }
    };

    const onRowClicked = (event: RowClickedEvent) => {
        if (!disabled) {
            onRowClick?.(event.data);
        }
    };

    const onCellValueChanged = (params: CellValueChangedEvent) => {
        if (onCellChange) {
            const rowData = params.data;
            const column = params.column.getColId();
            const value = params.newValue;

            onCellChange(rowData, column, value);
        }
    };

    const onActionClick = (rowIndex: number, column: string, node: RowNode) => {
        onAction?.(rowIndex, column, node.data);
    };

    useEffect(() => {
        if (refGridApi.current) {
            processTableData();
        }
    }, [processTableData]);

    useEffect(() => {
        return () => {
            if (popup) {
                popup.current?.remove();
            }
        };
    });

    React.Children.forEach(
        children,
        (child: React.ReactElement<IColumnProps>) => {
            let type = columnTypeSwitch.find(
                (columnType) => columnType.subcomponent === child.type
            )?.type;

            if (child.type === TableColumn) {
                type = child.props.type;
            }

            if (!type) {
                throw new Error("Table subcomponents must be valid Columns");
            }

            const {
                type: _columnType,
                column,
                children,
                onValidate,
                min: numberMin,
                max: numberMax,
                decimalCount: numberDecimalCount,
                values: drpValues,
                icon: actionIcon,
                tooltip: actionTooltip,
                disabledFormat,
                editable,
                search,
                disableNavigableFocus,
                ...additionalProperties
            } = (child.props as unknown) as IColumnProps &
                INumberColumnProps &
                IDropdownColumnProps &
                IActionColumnProps &
                ITextLinkColumnProps &
                IDropdownGroupColumnProps;

            const colDef: ColDef = {
                colId: column,
                field: column,
                headerName: children || "",
                editable: editable ?? false,
                suppressNavigable: disableNavigableFocus,
                ...additionalProperties,
                ...typeColDefMap.get(type),
            };

            if (type === "number") {
                colDef.cellRendererParams = {
                    decimalCount: numberDecimalCount,
                    disabledFormat,
                };

                colDef.cellEditorSelector = (_params: ICellEditorParams) => {
                    return {
                        component: "numberEditor",
                        params: {
                            min: numberMin,
                            max: numberMax,
                            decimalCount: numberDecimalCount,
                            editable,
                        },
                    };
                };
            }

            if (type === "checkbox") {
                colDef.cellRendererParams = {
                    disabled: !child.props.editable || !!disabled,
                };
            }

            if (type === "dropdown") {
                colDef.cellEditorSelector = (_params: ICellEditorParams) => {
                    return {
                        component: "dropdownEditor",
                        params: {
                            options: drpValues,
                            width: additionalProperties.width,
                        },
                    };
                };

                colDef.valueSetter = (params: ValueSetterParams) => {
                    params.data[column] = drpValues.find(
                        (drpValue) => drpValue.display === params.newValue
                    )?.value;

                    return true;
                };

                colDef.valueFormatter = (params: ValueFormatterParams) => {
                    const value = params.value;

                    if (value === undefined) {
                        return value;
                    }

                    return drpValues.find(
                        (drpValue) => drpValue.value === value
                    )?.display;
                };
            }

            if (type === "dropdownGroup") {
                colDef.cellEditorSelector = (_params: ICellEditorParams) => {
                    return {
                        component: "dropdownGroupEditor",
                        params: {
                            options: drpValues,
                            search,
                            width: additionalProperties.width,
                        },
                    };
                };

                colDef.valueSetter = (params: ValueSetterParams) => {
                    params.data[column] = findOptionByDisplay(
                        drpValues,
                        params.newValue
                    )?.value;

                    return true;
                };

                colDef.valueFormatter = (params: ValueFormatterParams) => {
                    const value = params.value;

                    if (value === undefined) {
                        return value;
                    }

                    return findOptionByValue(drpValues, value)?.display;
                };
            }

            if (type === "action") {
                colDef.cellRendererParams = {
                    colId: column,
                    icon: actionIcon,
                    tooltip: actionTooltip,
                };
            }

            if (type === "date") {
                colDef.comparator = (dateA: Moment, dateB: Moment) => {
                    return dateA.diff(dateB);
                };
                colDef.valueFormatter = (params: ValueFormatterParams) => {
                    const date = moment(params.value).format("DD.MM.YYYY");
                    return date;
                };
            }

            columnValidations[column] = onValidate;
            columnDefinitions.push(colDef);
        }
    );

    defaultColDef.cellClassRules = {
        invalid: (params: CellClassParams) => {
            const row = params.data;
            const column = params.colDef.colId!;

            if (!row.isRowCreator && columnValidations[column]) {
                const result = columnValidations[column]!(row[column]);
                return !!result;
            }

            return false;
        },
        cellUndefinedValue: (params: CellClassParams) => {
            const row = params.data;
            const column = params.colDef.colId!;

            return row[column] === undefined;
        },
        cellDisabled: (params: CellClassParams) => {
            const cellRendererParams = params.colDef.cellRendererParams;
            return cellRendererParams?.disabled;
        },
    };

    return (
        <div className={"ag-theme-material"}>
            <AgGrid
                api={refGridApi.current}
                getRowNodeId={(data) => data.id}
                defaultColDef={defaultColDef}
                columnDefs={columnDefinitions}
                rowData={refData.current}
                domLayout={"autoHeight"}
                headerHeight={50}
                rowHeight={40}
                colWidth={colWidth}
                overlayNoRowsTemplate={noRowsMessage}
                rowClass={onRowClick ? "ag-row-hasonrowclick" : undefined}
                suppressColumnVirtualisation={process.env.NODE_ENV === "test"}
                suppressPropertyNamesCheck
                stopEditingWhenGridLosesFocus
                popupParent={document.body}
                postProcessPopup={(params: PostProcessPopupParams) =>
                    (popup.current = params.ePopup)
                }
                frameworkComponents={{
                    numberEditor: NumberEditor,
                    dropdownEditor: DropdownEditor,
                    dropdownGroupEditor: DropdownGroupEditor,

                    numberRenderer: NumberRenderer,
                    checkboxRenderer: CheckboxRenderer,
                    actionRenderer: ActionRenderer,
                    textLinkRenderer: TextLinkRenderer,
                }}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
                onRowClicked={onRowClick ? onRowClicked : undefined}
                onCellValueChanged={onCellValueChanged}
                postSort={(nodes) =>
                    nodes.sort((a, b) => {
                        if (!a.data.isRowCreator && b.data.isRowCreator) {
                            return -1;
                        }

                        if (a.data.isRowCreator && !b.data.isRowCreator) {
                            return 1;
                        }

                        return 0;
                    })
                }
            />
        </div>
    );
};

const TableColumn: React.FC<IColumnProps> = () => <></>;
const TableTextColumn: React.FC<ITypedColumnProps> = () => <></>;
const TableNumberColumn: React.FC<INumberColumnProps> = () => <></>;
const TableCheckboxColumn: React.FC<ITypedColumnProps> = () => <></>;
const TableDropdownColumn: React.FC<IDropdownColumnProps> = () => <></>;
const TableActionColumn: React.FC<IActionColumnProps> = () => <></>;
const TableDateColumn: React.FC<IDateColumnProps> = () => <></>;
const TableTextLinkColumn: React.FC<ITextLinkColumnProps> = () => <></>;
const TableDropdownGroupColumn: React.FC<IDropdownGroupColumnProps> = () => (
    <></>
);

Table.Column = TableColumn;
Table.TextColumn = TableTextColumn;
Table.NumberColumn = TableNumberColumn;
Table.CheckboxColumn = TableCheckboxColumn;
Table.DropdownColumn = TableDropdownColumn;
Table.ActionColumn = TableActionColumn;
Table.DateColumn = TableDateColumn;
Table.TextLinkColumn = TableTextLinkColumn;
Table.DropdownGroupColumn = TableDropdownGroupColumn;

const columnTypeSwitch: {
    subcomponent:
        | React.FC<ITypedColumnProps>
        | React.FC<IDropdownColumnProps>
        | React.FC<IActionColumnProps>
        | React.FC<IDateColumnProps>
        | React.FC<ITextLinkColumnProps>
        | React.FC<IDropdownGroupColumnProps>;
    type: TableColumnType;
}[] = [
    {
        subcomponent: TableTextColumn,
        type: "text",
    },
    {
        subcomponent: TableNumberColumn,
        type: "number",
    },
    {
        subcomponent: TableCheckboxColumn,
        type: "checkbox",
    },
    {
        subcomponent: TableDropdownColumn,
        type: "dropdown",
    },
    {
        subcomponent: TableActionColumn,
        type: "action",
    },
    {
        subcomponent: TableDateColumn,
        type: "date",
    },
    {
        subcomponent: TableTextLinkColumn,
        type: "textLink",
    },
    {
        subcomponent: TableDropdownGroupColumn,
        type: "dropdownGroup",
    },
];
interface INumberEditorProps {
    value: number | undefined;
    node: RowNode;
    column: Column;
    min?: number;
    max?: number;
    decimalCount?: number;
    editable?: boolean;
}

interface IDropdownEditorProps {
    value: DropdownValue;
    node: RowNode;
    column: Column;
    options: DropdownOption[];
    width?: number;
}

interface INumberRendererProps {
    node: RowNode;
    column: Column;
    decimalCount?: number;
    disabledFormat?: boolean;
}

interface ICheckboxRendererProps {
    value: boolean | undefined;
    node: RowNode;
    column: Column;
    disabled: boolean;
}

interface IActionRendererProps {
    node: RowNode;
    column: Column;
    icon: SemanticICONS;
    tooltip?: string;
}

interface ITextLinkRendererProps {
    node: RowNode;
    column: Column;
}

interface IDropdownGroupEditorProps {
    value: DropdownValue;
    node: RowNode;
    column: Column;
    options: DropdownGroupOption[];
    width?: number;
    search?: boolean;
}

export const NumberEditor: React.FC<INumberEditorProps> = forwardRef(
    ({ value: propsValue, min, max, decimalCount, editable }, ref) => {
        const fieldRef = useRef<any>();
        const [value, setValue] = useState<number | undefined>(propsValue);

        const clampValue = (valueToBeClamped: number | undefined) => {
            if (valueToBeClamped !== undefined) {
                if (min !== undefined && valueToBeClamped < min) {
                    return min;
                }

                if (max !== undefined && valueToBeClamped > max) {
                    return max;
                }
            }

            return valueToBeClamped;
        };

        useImperativeHandle(ref, () => ({
            focusIn: true,
            focusOut: true,
            getValue: () => clampValue(value),
            afterGuiAttached: () => {
                setValue(propsValue);
                fieldRef.current?.focus();
                fieldRef.current?.select();
            },
        }));

        return (
            <NumberField
                value={value}
                minValue={min}
                maxValue={max}
                disabled={!editable}
                decimalCount={decimalCount}
                onChange={(newValue: number | undefined) => setValue(newValue)}
                getRef={(ref: any) => (fieldRef.current = ref)}
            />
        );
    }
);

export const DropdownEditor: React.FC<IDropdownEditorProps> = forwardRef(
    ({ value, options, column }, ref) => {
        const [drpValue, setValue] = useState<DropdownValue | undefined>(value);

        useImperativeHandle(ref, () => ({
            focusOut: true,
            isPopup: () => true,
            getValue: () =>
                drpValue
                    ? options.find((option) => option.value === drpValue)
                          ?.display
                    : drpValue,
            afterGuiAttached: () => setValue(value),
        }));

        return (
            <div
                style={{
                    width: column.getActualWidth(),
                }}
            >
                <Dropdown
                    value={drpValue}
                    options={options}
                    defaultOpen
                    onChange={(newValue) => setValue(newValue)}
                    data-testid={"dropdown_row"}
                />
            </div>
        );
    }
);

const findOptionByValue = (
    options: DropdownGroupOption[],
    value: DropdownValue
): DropdownOption | undefined => {
    return findOption(options, value, "value");
};

const findOptionByDisplay = (
    options: DropdownGroupOption[],
    displayValue: DropdownValue
): DropdownOption | undefined => {
    return findOption(options, displayValue, "display");
};

const findOption = (
    options: DropdownGroupOption[],
    value: DropdownValue,
    propName: "value" | "display"
): DropdownOption | undefined => {
    let foundOption = undefined;
    options.find((option) =>
        option.items.find((item) => {
            if (item[propName] === value) {
                foundOption = item;

                return true;
            } else {
                return false;
            }
        })
    );

    return foundOption;
};

export const DropdownGroupEditor: React.FC<IDropdownGroupEditorProps> = forwardRef(
    ({ value, options, search, column }, ref) => {
        const [drpValue, setValue] = useState<DropdownValue | undefined>(value);
        let drpRef = useRef<any>();

        useImperativeHandle(ref, () => ({
            focusIn: true,
            focusOut: true,
            isPopup: () => true,
            getValue: () => {
                if (!drpValue) {
                    return drpValue;
                }

                let displayValue = findOptionByValue(options, drpValue);

                return displayValue?.display ? displayValue.display : drpValue;
            },
            afterGuiAttached: () => {
                const searchInput = drpRef.current.children[1].children[0];
                searchInput.focus();
                setValue(value);
            },
        }));

        return (
            <div style={{ width: column.getActualWidth() }}>
                <DropdownGroup
                    value={drpValue}
                    options={options}
                    defaultOpen
                    search={search}
                    onChange={(newValue) => setValue(newValue)}
                    data-testid={"dropdownGroup_row"}
                    getRef={(el) => {
                        drpRef.current = el;
                    }}
                />
            </div>
        );
    }
);

export const NumberRenderer: React.FC<INumberRendererProps> = ({
    node,
    column,
    decimalCount,
    disabledFormat,
}) => {
    const value = node.data[column.getColId()];
    const displayValue = !disabledFormat
        ? formatValue(value, decimalCount)
        : value;
    return <div>{displayValue}</div>;
};

export const CheckboxRenderer: React.FC<ICheckboxRendererProps> = forwardRef(
    ({ value, disabled }, ref) => {
        const [checked, setChecked] = useState<boolean | undefined>(value);

        useImperativeHandle(ref, () => ({
            refresh: (params: {
                value: React.SetStateAction<boolean | undefined>;
            }) => {
                if (params.value !== checked) {
                    setChecked(params.value);
                }

                return true;
            },
        }));

        if (checked !== undefined) {
            return (
                <div data-testid={"checkbox_cell"}>
                    <Checkbox checked={checked} disabled={disabled} />
                </div>
            );
        } else {
            return <></>;
        }
    }
);

export const ActionRenderer: React.FC<IActionRendererProps> = ({
    icon,
    tooltip,
    node,
    column,
}) => {
    const colId = column.getColId();
    const row = node.data;

    return !row.isRowCreator && row[colId] ? (
        <div style={{ alignItems: "stretch", cursor: "pointer" }}>
            <Icon name={icon} title={tooltip} data-testid={"icon_row"} />
        </div>
    ) : (
        <></>
    );
};

export const TextLinkRenderer: React.FC<ITextLinkRendererProps> = ({
    column,
    node,
}) => {
    const columnId = column.getColId();
    const value = node.data[columnId];
    const link = node.data["links"][columnId];

    return <NavLink to={link}>{value}</NavLink>;
};
