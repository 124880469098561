import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { Responsive, WindowWidth } from "./Responsive";
interface IBaseProps {
    label: string;
    onAction: () => void;
    fluid?: boolean;
    disabled?: boolean;
    loading?: boolean;
}

interface IPrimaryProps extends IBaseProps {
    primary: true;
    secondary?: false;
    negative?: false;
}

interface ISecondaryProps extends IBaseProps {
    primary?: false;
    secondary: true;
    negative?: false;
}

interface INegativeProps extends IBaseProps {
    primary?: false;
    secondary?: false;
    negative: true;
}

type UnionProps = IPrimaryProps | ISecondaryProps | INegativeProps | IBaseProps;

export const ResponsiveButton: React.FC<UnionProps> = (props) => {
    const {
        label,
        fluid: propsFluid,
        disabled,
        primary,
        secondary,
        negative,
        onAction,
        loading,
    } = { ...props };

    const [fluid, setFluid] = useState(propsFluid);

    const onWidthChange = (width: number) => {
        if (width < WindowWidth.min) {
            setFluid(fluid === undefined ? true : fluid);
        } else {
            setFluid(fluid === undefined ? false : fluid);
        }
    };

    return (
        <Responsive onWidthChange={onWidthChange}>
            <Button
                key={label}
                className={"responsive-button"}
                disabled={disabled || loading}
                primary={primary}
                secondary={secondary}
                negative={negative}
                onClick={onAction}
                content={label.toLocaleUpperCase()}
                loading={loading}
                fluid={fluid === undefined ? false : fluid}
            />
        </Responsive>
    );
};
