import React, { CSSProperties, useState } from "react";
import FlexView from "react-flexview";
import { Loading } from "./Loading";
import { Responsive, WindowWidth } from "./Responsive";

const menuWidth = 248;
interface IProps {
    children: React.ReactElement | React.ReactElement[];
    style?: CSSProperties;
    loading?: boolean;
}

export const ViewContainer: React.FC<IProps> = ({
    children,
    style,
    loading,
}) => {
    const [containerWidth, setContainerWidth] = useState<number | undefined>();
    const [maxWidth, setMaxWidth] = useState<number | undefined>();

    const onWidthChange = (width: number) => {
        let containerWidth: number | undefined = 1850;
        const maxWidth = width - menuWidth;

        if (width < WindowWidth.veryLarge) {
            containerWidth = 1500;
        }
        if (width < WindowWidth.large) {
            containerWidth = 1127;
        }
        if (width < WindowWidth.medium) {
            containerWidth = 933;
        }
        if (width < WindowWidth.small) {
            containerWidth = 723;
        }
        if (width < WindowWidth.min) {
            containerWidth = maxWidth;
        }

        setContainerWidth(containerWidth);
        setMaxWidth(maxWidth);
    };

    return (
        <FlexView column hAlignContent={"center"}>
            <Loading active={loading ? loading : false} />
            <Responsive onWidthChange={onWidthChange}>
                {containerWidth ? (
                    <div style={{ ...style, width: containerWidth, maxWidth }}>
                        {children}
                    </div>
                ) : (
                    <div style={style}>{children}</div>
                )}
            </Responsive>
        </FlexView>
    );
};
