import React, {
    useState,
    SyntheticEvent,
    useEffect,
    useRef,
    useCallback,
} from "react";
import {
    Dropdown as UIDropdown,
    Form,
    Label,
    Icon,
    LabelProps,
} from "semantic-ui-react";
import uniqueId from "lodash.uniqueid";
import { DropdownOption, DropdownValue } from "./Dropdown";

export interface MultiDropdownProps {
    values: DropdownValue[];
    options: DropdownOption[];
    label: string;
    required?: boolean;
    error?: string;
    disabled?: boolean;
    onChange: (values: DropdownValue[]) => void;
    onValidate?: (values: DropdownValue[]) => void;
    onFocus?: () => void;
    placeholder?: string;
    className?: string;
    search?: boolean;
    noResultsMessage?: string;
}

export const MultiDropdown: React.FC<MultiDropdownProps> = (props) => {
    const { values: propsValues } = props;
    const options = props.options.map(
        ({ value, display }: { value: string | number; display: string }) => ({
            key: value,
            value,
            text: display,
        })
    );

    const [id] = useState(uniqueId("dropdown_"));
    const [values, setValues] = useState<DropdownValue[]>(propsValues);
    const [isOpen, setOpen] = useState<boolean>(false);
    const timerRef = useRef<NodeJS.Timeout | number | null>(null);
    const [displayedError, setDisplayedError] = useState(props.error);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        }

        const newError = isOpen ? "" : props.error;

        if (newError) {
            timerRef.current = setTimeout(() => {
                setDisplayedError(newError);
            }, 250);
        } else {
            setDisplayedError(newError);
        }
    }, [props.error, isOpen]);

    const mount = useRef(true);

    useEffect(() => {
        if (mount.current) {
            mount.current = false;
            return;
        }
    }, [propsValues]); // eslint-disable-line react-hooks/exhaustive-deps

    const onOpen = useCallback(() => {
        setOpen(true);
        props.onFocus?.();
    }, [props.onFocus]);

    const onClose = useCallback(() => {
        setOpen(false);
        props.onValidate?.(values);
    }, [props.onValidate, values]);

    return (
        <Form.Field
            id={`multidrp_${id}`}
            className={props.className}
            aria-labelledby={id}
            value={values}
            options={options}
            fluid={true}
            selection={true}
            selectOnBlur={false}
            label={{
                children: props.label,
                id,
                htmlFor: `multidrp_${id}`,
            }}
            error={displayedError ? { content: displayedError } : undefined}
            required={props.required}
            disabled={props.disabled}
            onOpen={onOpen}
            onClose={onClose}
            placeholder={props.placeholder}
            onChange={(_e: SyntheticEvent, target: { value: string[] }) => {
                setValues(target.value);
                props.onChange(target.value);

                if (!isOpen) {
                    props.onValidate?.(target.value);
                }
            }}
            control={UIDropdown}
            multiple
            search={props.search !== false}
            renderLabel={renderLabel}
            clearable
            noResultsMessage={props.noResultsMessage}
        />
    );
};

const renderLabel = (
    item: { key: string; value: string; text: string },
    _index: number,
    defaultLabelProps: {
        active: boolean;
        as: string;
        onClick: (
            event: React.MouseEvent<HTMLElement, MouseEvent>,
            data: LabelProps
        ) => void;
        onRemove: (
            event: React.MouseEvent<HTMLElement, MouseEvent>,
            data: LabelProps
        ) => void;
        key: string;
    }
) => {
    const { onClick, active, as, key, onRemove } = defaultLabelProps;
    return (
        <Label
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                onClick(event, { ...defaultLabelProps, active: active });
            }}
            active={active}
            as={as}
            key={key}
        >
            {item["text"]}

            <Icon
                data-testid={`delete_icon_${key}`}
                name={"delete"}
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    onRemove(event, defaultLabelProps)
                }
            />
        </Label>
    );
};
