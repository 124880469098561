import uniqueId from "lodash.uniqueid";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
    Dropdown as UIDropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Ref,
} from "semantic-ui-react";

export type DropdownGroupOption = { header: string; items: DropdownOption[] };
export type DropdownOption = { value: string | number; display: string };
export type DropdownValue = string | number;

export interface IProps {
    value: DropdownValue | undefined;
    options: DropdownGroupOption[];
    label?: string;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    onChange: (value: DropdownValue | undefined) => void;
    onValidate?: (value: DropdownValue | undefined) => void;
    onFocus?: () => void;
    defaultOpen?: boolean;
    search?: boolean;
    noResultsMessage?: string;
    getRef?: (ref: HTMLElement | null) => void;
}

export const DropdownGroup: React.FC<IProps> = (props) => {
    const [id] = useState(uniqueId("dropdownGroup_"));
    const [value, setValue] = useState<DropdownValue | undefined>(props.value);
    const [closed, setClosed] = useState<boolean>(false);
    const { value: propsValue } = props;

    const mount = useRef(true);
    const timerRef = useRef<NodeJS.Timeout | number | null>(null);
    const [displayedError, setDisplayedError] = useState(props.error);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        }

        const newError = mount || closed ? props.error : "";

        if (newError) {
            timerRef.current = setTimeout(() => {
                setDisplayedError(newError);
            }, 250);
        } else {
            setDisplayedError(newError);
        }
    }, [props.error, closed]);

    useEffect(() => {
        if (mount.current) {
            mount.current = false;
            return;
        }

        if (!closed || propsValue !== value) {
            setValue(propsValue);
            props.onChange(propsValue);
            props.onValidate?.(propsValue);
        }
    }, [propsValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (closed) {
            props.onChange(value);
            props.onValidate?.(value);
        }
    }, [closed]); // eslint-disable-line react-hooks/exhaustive-deps

    const getOptions = () => {
        const options: DropdownProps[] = [];
        props.options.forEach((option, i) => {
            options.push({
                key: option.header,
                value: option.header,
                text: option.header,
                as: UIDropdown.Header,
                disabled: true,
                className:
                    i === 0
                        ? "dropdowngroup-header-first"
                        : "dropdowngroup-header",
            });
            option.items.forEach((item) => {
                options.push({
                    key: item.value,
                    value: item.value,
                    text: item.display,
                });
            });
        });
        return options;
    };

    return (
        <Ref
            innerRef={(ref) => {
                props.getRef?.(ref);
            }}
        >
            <Form.Field
                defaultOpen={props.defaultOpen}
                id={`drpGroup_${id}`}
                value={value}
                options={getOptions()}
                fluid={true}
                selection={true}
                selectOnBlur={false}
                aria-labelledby={id}
                label={{
                    children: props.label,
                    id,
                    htmlFor: `drp_${id}`,
                }}
                error={
                    displayedError && (!focused || closed)
                        ? { content: displayedError }
                        : undefined
                }
                required={props.required}
                disabled={props.disabled}
                search={
                    props.search
                        ? (
                              options: DropdownItemProps[],
                              searchValue: string
                          ) => {
                              let searchResult: DropdownItemProps[] = [];
                              let group: DropdownItemProps[] = [];
                              options.forEach((option, i) => {
                                  if (option.as !== undefined) {
                                      if (group.length > 1) {
                                          searchResult = searchResult.concat(
                                              group
                                          );
                                      }
                                      group = [];
                                      group.push(option);
                                      return;
                                  }

                                  if (
                                      (option.text as string)
                                          .toLocaleLowerCase()
                                          .includes(
                                              searchValue.toLocaleLowerCase()
                                          )
                                  ) {
                                      group.push(option);
                                  }
                                  if (
                                      i === options.length - 1 &&
                                      group.length > 1
                                  ) {
                                      searchResult = searchResult.concat(group);
                                  }
                              });

                              return searchResult;
                          }
                        : false
                }
                noResultsMessage={props.noResultsMessage}
                onOpen={() => setClosed(false)}
                onClose={() => setClosed(true)}
                onChange={(
                    event: SyntheticEvent<HTMLElement, Event>,
                    data: DropdownProps
                ) => {
                    if (
                        props.required === false &&
                        (event.target as HTMLElement).className ===
                            "dropdown icon clear"
                    ) {
                        const newValue = data.value as DropdownValue;
                        props.onChange(newValue);
                        props.onValidate?.(newValue);
                    }
                    setValue(data.value as DropdownValue);
                }}
                onFocus={() => {
                    setFocused(true);
                    props.onFocus?.();
                }}
                onBlur={() => setFocused(false)}
                control={UIDropdown}
                clearable={props.required === false}
            />
        </Ref>
    );
};
