import moment, { Moment } from "moment";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import FlexView from "react-flexview/lib";
import { Icon, Popup } from "semantic-ui-react";
import _ from "lodash";
import { TranslateDateFormatFunction } from "./I18nTypes";

interface IProps {
    open: boolean;
    inputRef: React.RefObject<HTMLInputElement>;
    onClose: () => void;
    value: Moment | undefined;
    onPick: (date: Moment) => void;
    onSelection?: (date: Moment) => void;
    getApi?: (api: ICalendarPickerApi) => void;
    translateDateFormat: TranslateDateFormatFunction;
}

export interface ICalendarPickerApi {
    moveUp: () => void;
    moveDown: () => void;
    moveLeft: () => void;
    moveRight: () => void;
}

export const DateInputPicker: React.FC<IProps> = ({
    open,
    inputRef,
    onClose,
    value,
    onPick,
    onSelection,
    getApi,
    translateDateFormat: tDF,
}) => {
    const calendarRef = useRef<HTMLDivElement>(null);

    return (
        <Popup
            context={inputRef}
            open={open}
            onClose={onClose}
            style={{ padding: 0 }}
            className={"date-picker"}
        >
            <CalendarPicker
                translateDateFormat={tDF}
                ref={calendarRef}
                initialValue={value}
                onPick={onPick}
                onSelection={onSelection}
                getApi={getApi}
            />
        </Popup>
    );
};

interface ICalendarProps {
    initialValue: Moment | undefined;
    onPick?: (date: Moment) => void;
    onSelection?: (date: Moment) => void;
    getApi?: (api: ICalendarPickerApi) => void;
    translateDateFormat: TranslateDateFormatFunction;
}

const Header: React.FC<{
    children: React.ReactElement | React.ReactElement[] | string | undefined;
}> = ({ children }) => (
    <FlexView column className="calendar-header">
        {children}
    </FlexView>
);

const Body: React.FC<{
    children: React.ReactElement | React.ReactElement[] | string | undefined;
}> = ({ children }) => (
    <FlexView column className="calendar-body">
        {children}
    </FlexView>
);

const Cell: React.FC<{
    width: number;
    children: React.ReactElement | React.ReactElement[] | string | undefined;
    disabled?: boolean;
    today?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    selected?: boolean;
}> = ({ width, children, disabled, today, onClick, selected }) => (
    <FlexView
        className={`calendar-cell ${disabled ? "disabled" : ""} ${
            today ? "today" : ""
        } ${selected ? "selected" : ""}`}
        column
        hAlignContent="center"
        vAlignContent="center"
        width={`${(width * 100) / 7}%`}
        onClick={onClick}
    >
        {children}
    </FlexView>
);

const Row: React.FC<{
    children: React.ReactElement | React.ReactElement[] | string | undefined;
    style?: CSSProperties;
}> = ({ children }) => (
    <FlexView
        className="calendar-row"
        hAlignContent="center"
        height={"2em"}
        vAlignContent="center"
    >
        {children}
    </FlexView>
);

const CalendarPicker = React.forwardRef<HTMLDivElement, ICalendarProps>(
    (
        { initialValue, onPick, onSelection, getApi, translateDateFormat: tDF },
        forwardRef
    ) => {
        const [date, setDate] = useState(
            initialValue?.clone() || moment().startOf("day")
        );
        const [displayDate, setDisplayDate] = useState(
            date.clone().startOf("month")
        );
        const [monthStart, setMonthStart] = useState(
            displayDate.clone().startOf("month")
        );
        const [monthEnd, setMonthEnd] = useState(
            displayDate.clone().endOf("month")
        );

        useEffect(() => {
            setMonthStart(displayDate.clone().startOf("month"));
            setMonthEnd(displayDate.clone().endOf("month"));
        }, [displayDate]);

        useEffect(() => {
            setDisplayDate(date.clone());
        }, [date]);

        useEffect(() => {
            setDate(initialValue?.clone() || moment().startOf("day"));
        }, [initialValue]);

        const weekStart = monthStart.clone().startOf("isoWeek");
        const weekEnd = monthEnd.clone().endOf("isoWeek");
        const days = [];
        const today = moment().startOf("day");

        const moveUp = () => {
            onSelection?.(date.clone().add(-7, "days"));
        };
        const moveDown = () => {
            onSelection?.(date.clone().add(7, "days"));
        };
        const moveLeft = () => {
            onSelection?.(date.clone().add(-1, "days"));
        };
        const moveRight = () => {
            onSelection?.(date.clone().add(1, "days"));
        };

        getApi?.({ moveUp, moveDown, moveLeft, moveRight });

        for (
            let m = moment(weekStart);
            m.isSameOrBefore(weekEnd);
            m.add(1, "days")
        ) {
            const day = m.clone();
            const disabled = !m.isBetween(monthStart, monthEnd, "days", "[]");
            days.push(
                <Cell
                    key={`${days.length}`}
                    width={1}
                    disabled={disabled}
                    today={m.isSame(today)}
                    selected={m.isSame(date)}
                    onClick={() => {
                        onPick?.(day);
                    }}
                >
                    {m.format("DD")}
                </Cell>
            );
        }
        const weeks = _.chunk(days, 7);

        return (
            <FlexView
                column
                width="20em"
                ref={forwardRef}
                tabIndex={0}
                style={{ outlineWidth: 0 }}
                onKeyDown={(ke) => {
                    if (ke.key === "ArrowUp") {
                        moveUp();
                    }
                    if (ke.key === "ArrowDown") {
                        moveDown();
                    }
                    if (ke.key === "ArrowLeft") {
                        moveLeft();
                    }
                    if (ke.key === "ArrowRight") {
                        moveRight();
                    }
                }}
            >
                <Header>
                    <Row>
                        <Cell
                            width={1}
                            onClick={(e) => {
                                setDisplayDate(
                                    displayDate.clone().add(-1, "month")
                                );
                            }}
                        >
                            <Icon name="angle left" />
                        </Cell>
                        <Cell width={5}>
                            {displayDate
                                ? `${tDF(
                                      displayDate,
                                      "month"
                                  )} ${displayDate.format("YYYY")}`
                                : undefined}
                        </Cell>
                        <Cell
                            width={1}
                            onClick={(e) =>
                                setDisplayDate(
                                    displayDate.clone().add(1, "month")
                                )
                            }
                        >
                            <Icon name="angle right" />
                        </Cell>
                    </Row>
                    <Row>
                        <Cell width={1}>
                            {tDF(moment().day(1), "day", true)}
                        </Cell>
                        <Cell width={1}>
                            {tDF(moment().day(2), "day", true)}
                        </Cell>
                        <Cell width={1}>
                            {tDF(moment().day(3), "day", true)}
                        </Cell>
                        <Cell width={1}>
                            {tDF(moment().day(4), "day", true)}
                        </Cell>
                        <Cell width={1}>
                            {tDF(moment().day(5), "day", true)}
                        </Cell>
                        <Cell width={1}>
                            {tDF(moment().day(6), "day", true)}
                        </Cell>
                        <Cell width={1}>
                            {tDF(moment().day(0), "day", true)}
                        </Cell>
                    </Row>
                </Header>
                <Body>
                    {weeks.map((week, index) => (
                        <Row key={`${index}`}>{week}</Row>
                    ))}
                </Body>
            </FlexView>
        );
    }
);
