import React, { useState, SyntheticEvent, useEffect, useRef } from "react";
import {
    Dropdown as UIDropdown,
    DropdownProps as SemanticDropdownProps,
    Form,
    SemanticWIDTHS,
} from "semantic-ui-react";
import uniqueId from "lodash.uniqueid";

export type DropdownOption = { value: string | number; display: string };
export type DropdownValue = string | number | undefined;

export interface DropdownProps {
    value: DropdownValue | undefined;
    options: DropdownOption[];
    label?: string;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    onChange: (value: DropdownValue) => void;
    onValidate?: (value: DropdownValue) => void;
    onFocus?: () => void;
    placeholder?: string;
    className?: string;
    defaultOpen?: boolean;
    search?: boolean;
    noResultsMessage?: string;
    width?: SemanticWIDTHS;
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
    const options = props.options.map(
        ({ value, display }: { value: string | number; display: string }) => ({
            key: value,
            value,
            text: display,
        })
    );
    const [id] = useState(uniqueId("dropdown_"));
    const [value, setValue] = useState<DropdownValue>(props.value);
    const [closed, setClosed] = useState<boolean>(false);
    const { value: propsValue } = props;

    const mount = useRef(true);
    const timerRef = useRef<NodeJS.Timeout | number | null>(null);
    const [displayedError, setDisplayedError] = useState(props.error);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        }

        const newError = mount || closed ? props.error : "";

        if (newError) {
            timerRef.current = setTimeout(() => {
                setDisplayedError(newError);
            }, 250);
        } else {
            setDisplayedError(newError);
        }
    }, [props.error, closed]);

    useEffect(() => {
        if (mount.current) {
            mount.current = false;
            return;
        }

        if (!closed || propsValue !== value) {
            setValue(propsValue);
            props.onChange(propsValue);
            props.onValidate?.(propsValue);
        }
    }, [propsValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (closed) {
            props.onChange(value);
            props.onValidate?.(value);
        }
    }, [closed]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form.Field
            defaultOpen={props.defaultOpen}
            className={props.className}
            id={`drp_${id}`}
            value={value ?? ""}
            options={options}
            fluid={true}
            selection={true}
            selectOnBlur={false}
            aria-labelledby={id}
            placeholder={props.placeholder}
            width={props.width}
            label={{
                children: props.label,
                id,
                htmlFor: `drp_${id}`,
            }}
            error={
                displayedError && (!focused || closed)
                    ? { content: displayedError }
                    : undefined
            }
            required={props.required}
            disabled={props.disabled}
            search={props.search}
            noResultsMessage={props.noResultsMessage}
            onOpen={() => setClosed(false)}
            onClose={() => setClosed(true)}
            onChange={(
                event: SyntheticEvent<HTMLElement, Event>,
                data: SemanticDropdownProps
            ) => {
                const newValue =
                    data.value === null || data.value === ""
                        ? undefined
                        : (data.value as DropdownValue);

                if (
                    props.required === false &&
                    (event.target as HTMLElement).className ===
                        "dropdown icon clear"
                ) {
                    props.onChange(newValue);
                    props.onValidate?.(newValue);
                }

                setValue(newValue);
            }}
            onFocus={() => {
                setFocused(true);
                props.onFocus?.();
            }}
            onBlur={() => setFocused(false)}
            control={UIDropdown}
            clearable={props.required === false}
        />
    );
};
