import React from "react";
import { ResponsiveButton } from "./ResponsiveButton";
import { Modal } from "semantic-ui-react";
import FlexView from "react-flexview";

declare type buttonType = "primary" | "secondary" | "negative" | "default";

export interface SimpleModalButton {
    text: string;
    id: string;
    type?: buttonType;
    loading?: boolean;
    disabled?: boolean;
}

interface IProps {
    text: string;
    buttons: SimpleModalButton[];
    onButtonClick: (id: string) => void;
}
const margin = "2em";

export const SimpleModal: React.FC<IProps> = (props: IProps) => {
    return (
        <>
            <Modal
                size="tiny"
                open
                closeOnDimmerClick={false}
                data-testid="modal"
            >
                <Modal.Header>{props.text}</Modal.Header>
                <Modal.Actions>
                    <FlexView
                        wrap
                        hAlignContent={"right"}
                        style={{
                            marginBottom: `-${margin}`,
                            marginLeft: `-${margin}`,
                        }}
                    >
                        {props.buttons.map((button, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        marginLeft: margin,
                                        marginBottom: margin,
                                    }}
                                >
                                    <ResponsiveButton
                                        fluid={false}
                                        label={button.text}
                                        primary={button.type === "primary"}
                                        secondary={button.type === "secondary"}
                                        negative={button.type === "negative"}
                                        loading={button.loading}
                                        disabled={button.disabled}
                                        onAction={() =>
                                            props.onButtonClick(button.id)
                                        }
                                    />
                                </div>
                            );
                        })}
                    </FlexView>
                </Modal.Actions>
            </Modal>
        </>
    );
};
