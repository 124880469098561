import React from "react";
import { Image, Container, Menu } from "semantic-ui-react";
import logo from "../img/mirus_logo_transparent.png";
import { useHistory } from "react-router-dom";
import { MainMenuItem, IMenuItem } from "./MainMenuItem";
import { MainMenuUserItem } from "./MainMenuUserItem";
import { IMenu, MainMenuDropdown } from "./MainMenuDropdown";

export interface IMainMenuItems {
    menu: (IMenuItem | IMenu)[];
    userMenu: IMenuItem[];
}
interface IProps {
    homeLink: string;
    items: IMainMenuItems;
}
export const MainMenu: React.FC<IProps> = ({ children, homeLink, items }) => {
    const history = useHistory();

    const menuItems = items.menu.map((item) => {
        if (item["items"]) {
            return (
                <MainMenuDropdown
                    key={item.text}
                    text={item.text}
                    items={(item as IMenu).items}
                />
            );
        } else {
            return (
                <MainMenuItem
                    key={item.text + (item as IMenuItem).link}
                    text={item.text}
                    link={(item as IMenuItem).link}
                />
            );
        }
    });

    return (
        <Menu className="main-menu" fixed="top">
            <Container>
                <Menu.Item
                    className="menu-item"
                    onClick={() => history.push(homeLink)}
                    data-testid="logo"
                >
                    <Image size="tiny" src={logo} />
                </Menu.Item>
                {menuItems}
                <MainMenuUserItem items={items.userMenu} />
            </Container>
            <div
                style={{
                    float: "left",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    visibility: "hidden",
                }}
            />
        </Menu>
    );
};
