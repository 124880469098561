import { RenderResult, within } from "@testing-library/react";

export const queryColumnHeaderByColId = (
    instance: RenderResult,
    colId: string
): HTMLElement | null => {
    const result = instance
        .queryAllByRole("columnheader")
        .find((element) => element.getAttribute("col-id") === colId);

    return result ? result : null;
};

export const queryByHeaderText = (instance: RenderResult, text: string) =>
    instance
        .queryAllByRole("presentation")
        .find((el) =>
            el.childElementCount ? within(el).queryByText(text) : false
        );

export const queryAllRows = (instance: RenderResult): HTMLElement[] =>
    within(instance.getAllByRole("rowgroup")[1]).queryAllByRole("row");

export const queryRowByText = (
    instance: RenderResult,
    text?: string
): HTMLElement | null =>
    within(instance.getAllByRole("rowgroup")[1]).queryByRole("row", {
        name: text,
    });

export const queryGridCellByColId = (
    row: HTMLElement,
    colId: string
): HTMLElement | null => {
    const result = within(row)
        .queryAllByRole("gridcell")
        .find((cell) => cell.getAttribute("col-id") === colId);

    return result ? result : null;
};
