import React from "react";
import FlexView from "react-flexview";

interface IProps {
    children?: React.ReactNode;
}
export const LoggedOutLayout: React.FC<IProps> = (props) => {
    return (
        <div className="loggedOutLayout">
            <FlexView
                style={{ minHeight: "51em" }}
                grow
                vAlignContent={"center"}
                hAlignContent={"center"}
            >
                <div className="content">{props.children}</div>
            </FlexView>
        </div>
    );
};
