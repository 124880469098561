import React from "react";
import { Menu } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

export interface IVerticalMenuItem {
    text: string;
    link: string;
    icon?: string;
}

export const VerticalMenuItem: React.FC<IVerticalMenuItem> = ({
    text,
    link,
    icon,
}) => {
    const history = useHistory();

    return (
        <Menu.Item
            content={text}
            onClick={() => history.push(link)}
            icon={icon}
        />
    );
};
