import _ from "lodash";
import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    KeyboardEvent,
    MouseEvent,
} from "react";
import { Form, Button, FormFieldProps, Label } from "semantic-ui-react";
import { ButtonSelectOption, ButtonSelectValue, VK } from "./ButtonSelect";

export interface IProps extends FormFieldProps {
    values: ButtonSelectValue[];
    options: ButtonSelectOption[];
    label: string;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    onChange: (values: ButtonSelectValue[]) => void;
    onValidate?: (values: ButtonSelectValue[]) => void;
}

export const MultiButtonSelect: React.FC<IProps> = (props) => {
    const {
        values: propsValues,
        options,
        label,
        error,
        required,
        disabled,
        onChange,
        onValidate,
    } = props;
    const buttonRef = useRef<Button>(null);
    const mount = useRef(true);

    const [checked, setChecked] = useState<ButtonSelectValue[]>(propsValues);
    const [selectedButton, setSelectedButton] = useState<number>(0);

    const moveLeft = useCallback(
        (event: KeyboardEvent) => {
            event.preventDefault();

            if (selectedButton === 0) {
                setSelectedButton(options.length - 1);
            } else {
                setSelectedButton((prevSelected) => --prevSelected);
            }
        },
        [options.length, selectedButton]
    );

    const moveRight = useCallback(
        (event: KeyboardEvent) => {
            event.preventDefault();

            if (selectedButton === options.length - 1) {
                setSelectedButton(0);
            } else {
                setSelectedButton((prevSelected) => ++prevSelected);
            }
        },
        [options.length, selectedButton]
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const keyPressed: {
                [keyCode: number]: (event: KeyboardEvent) => void;
            } = {
                [VK.UP]: moveLeft,
                [VK.LEFT]: moveLeft,
                [VK.RIGHT]: moveRight,
                [VK.DOWN]: moveRight,
            };

            keyPressed[event.keyCode]?.(event);
        },
        [moveLeft, moveRight]
    );

    useEffect(() => {
        if (!mount.current) {
            buttonRef.current?.focus();
        }
    }, [selectedButton]);

    useEffect(() => {
        if (mount.current) {
            mount.current = false;
        }
    }, [propsValues]);

    return (
        <Form.Field
            onChange={onChange}
            values={propsValues}
            options={options}
            label={{ children: label, "aria-label": label }}
            required={required}
            disabled={disabled}
            error={!!error}
            control={"div"}
        >
            <Button.Group
                fluid={true}
                onKeyDown={onKeyDown}
                className={`buttonSelect${error ? " invalid" : ""}`}
            >
                {options.map(({ value, display }, index) => {
                    return (
                        <Button
                            icon={
                                checked.find((item) => item === value)
                                    ? "check square outline"
                                    : "square outline"
                            }
                            key={index}
                            value={value}
                            content={display}
                            tabIndex={selectedButton === index ? 0 : -1}
                            ref={
                                selectedButton === index ? buttonRef : undefined
                            }
                            onClick={(
                                _e: MouseEvent<HTMLButtonElement>,
                                { value: newValue }
                            ) => {
                                const newChecked = _.clone(checked);
                                const foundIndex = checked.indexOf(newValue);

                                if (foundIndex > -1) {
                                    newChecked.splice(foundIndex, 1);
                                } else {
                                    newChecked.push(newValue);
                                }
                                setChecked(newChecked);
                                setSelectedButton(index);
                                onChange?.(newChecked);
                                onValidate?.(newChecked);
                            }}
                        />
                    );
                })}
            </Button.Group>
            {error && (
                <Label basic pointing="above" prompt>
                    {error}
                </Label>
            )}
        </Form.Field>
    );
};
