import React from "react";
import { Menu, Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { IMenuItem } from "./MainMenuItem";

export interface IMenu {
    text: string;
    items: IMenuItem[];
}

interface IProps extends IMenu {}

export const MainMenuDropdown: React.FC<IProps> = ({
    children,
    text,
    items,
}) => {
    const history = useHistory();

    return (
        <Dropdown
            className="menu-item"
            simple
            item
            trigger={<Menu.Item fitted content={text} />}
        >
            <Dropdown.Menu>
                {items!.map((item) => (
                    <Dropdown.Item
                        key={item.text + item.link}
                        text={item.text}
                        onClick={() => history.push(item.link)}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
