import React from "react";

export interface IHorizontalSpaceProps {
    small?: boolean;
    big?: boolean;
}

export const HorizontalSpace: React.FC<IHorizontalSpaceProps> = ({
    small,
    big,
}) => {
    let size = 2;

    if (small) {
        size = 1;
    }

    if (big) {
        size = 4;
    }

    return (
        <div
            style={{
                display: "inline-block",
                width: `${size}em`,
                minWidth: `${size}em`,
            }}
        />
    );
};
