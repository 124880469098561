import React, { useState, useEffect } from "react";
import {
    Button,
    Header,
    Icon,
    Segment,
    Dropdown as UIDropdown,
} from "semantic-ui-react";
import { Loading } from "./Loading";

interface IMenuItem {
    onItemClick: () => void;
    title: string;
}

interface IProps {
    open?: boolean;
    title: string;
    footer?: React.ReactNode;
    children: React.ReactNode;
    collapsible?: boolean;
    style?: object;
    onOpen?: () => void;
    onClose?: () => void;
    menuInHeader?: IMenuItem[];
    loading?: boolean;
}

export const CollapsibleSegment: React.FC<IProps> = (props) => {
    const [isOpen, setOpen] = useState<boolean>(
        props.open || !props.collapsible
    );

    useEffect(() => {
        if (props.open !== undefined) {
            setOpen(props.open);
        }
    }, [props.open]);

    return (
        <Segment.Group className="collapsibleSegment" style={props.style}>
            <Segment
                className="segment-header"
                data-testid="segment-header"
                onClick={
                    props.collapsible
                        ? () => {
                              if (!isOpen && props.onOpen) {
                                  props.onOpen();
                              }
                              if (isOpen && props.onClose) {
                                  props.onClose();
                              }
                              setOpen(!isOpen);
                          }
                        : undefined
                }
                style={{
                    cursor: !props.collapsible ? "default" : "pointer",
                }}
                disabled={props.loading}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {props.collapsible ? (
                            <Icon
                                name={isOpen ? "angle down" : "angle right"}
                            />
                        ) : undefined}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 0,
                        }}
                    >
                        <Header as="h3">{props.title}</Header>
                    </div>

                    {props.menuInHeader && (
                        <div
                            style={{
                                position: "absolute",
                                right: 0,
                                top: "13%",
                            }}
                        >
                            <UIDropdown
                                trigger={<Button icon="ellipsis vertical" />}
                                icon={null}
                                direction="left"
                                disabled={props.loading}
                            >
                                <UIDropdown.Menu>
                                    {props.menuInHeader.map((item, index) => (
                                        <UIDropdown.Item
                                            onClick={item.onItemClick}
                                            key={index}
                                        >
                                            {item.title}
                                        </UIDropdown.Item>
                                    ))}
                                </UIDropdown.Menu>
                            </UIDropdown>
                        </div>
                    )}
                </div>
            </Segment>
            {isOpen ? (
                <>
                    <Loading active={props.loading || false} />
                    <Segment disabled={props.loading || false}>
                        {props.children}
                    </Segment>
                </>
            ) : undefined}
            {props.footer && isOpen ? (
                <Segment style={{ backgroundColor: "#f9fafb" }}>
                    {props.footer}
                </Segment>
            ) : undefined}
        </Segment.Group>
    );
};
