import React from "react";

export interface IVerticalSpaceProps {
    small?: boolean;
    big?: boolean;
}

export const VerticalSpace: React.FC<IVerticalSpaceProps> = ({
    small,
    big,
}) => {
    let size = 2;

    if (small) {
        size = 1;
    }

    if (big) {
        size = 4;
    }

    return <div style={{ height: `${size}em`, minHeight: `${size}em` }} />;
};
